import { render, staticRenderFns } from "./zpzwxq.vue?vue&type=template&id=66fb2e88&scoped=true&"
import script from "./zpzwxq.vue?vue&type=script&lang=js&"
export * from "./zpzwxq.vue?vue&type=script&lang=js&"
import style0 from "./加入我们 - 建筑 - 建设 - 重庆渝隆远大住宅工业有限公司_files/CgAGTF1s3RWAMgNnAAA1V_ZsM2U309.css?vue&type=style&index=0&id=66fb2e88&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66fb2e88",
  null
  
)

export default component.exports