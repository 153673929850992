<template>
  <Layout :footer-style="2">
    <div v-html="showZcdNr">
    
 </div>
	<!--二级菜单区域 begin-->
 <!-- <div  >
        <el-tabs  v-model="cruName" v-if="ejcd.length >0"   @tab-click="getListData">
            
                                                                	  <el-tab-pane
           v-for="item in ejcd"
          :key="item.id"
          :value="item.id"
          :label="item.strName"
         :name="item.id"
       />
                                                             
        
     </el-tabs>
 			
        </div> -->
         <div id="ea_c">
            <div class="n_main">
                <div class="n_content">
                    <div class="n_content_left">
                    <div class="n_content_left_wrap">

                        <div id="n_content_left_cnt" class="n_content_left_cnt">
                            <ul id="ShoveWebControl_VMenu2" class="left_typeUl left_typeUl_n000" style="display: block;">
<ul id="left_typeUl_t200" class="left_typeUl left_typeUl_t200">

<li  v-for="item in ejcd"  id="left_typeLi_t200_1" data_id="2_5" :class="{'left_menu1_cur': cruId===item.id, 'left_menu1': cruId!=item.id}" type="t" index="1" level="0"  @click="getListData(item)" ><a  href="#" target="_self"><span name="ShoveWebControl_VMenu21654180832|T_NewsTypes|Id|5|Name">{{item.strName}}</span></a>
</li>

</ul>
</ul>
                       
                        </div>


                            <div id="n_content_right_name_r" class="n_content_right_name_r"> 
                                <ul>
                                    <li><span class="n_r_wz1">
                                        
                                        <span name="_n_r_wz1">您现在的位置：</span>
                                        
                                    </span><span class="n_r_wz2">
                                        
                                            <router-link   to="/">
               <span name="_n_r_wz2">首页</span>
                </router-link>
                                    </span><span class="n_r_wz3">→</span>
                                   <span class="n_r_wz4">
                                            <span id="ShoveWebControl_Text_news">
                                                 <router-link  :to="{name:'empty',params: {zcdid:zcdid,cruId:cruId,ejcd:ejcd,topath:'xwzx'}}">
               <a href="#" class="curr1">新闻中心</a>
                </router-link>
                                               
                                              </span>
                                        </span>
                                        <span class="n_r_wz5">→</span>
                                        <span class="n_r_wz6">
                                           
                                              <router-link   :to="{name:'empty',params: {zcdid:zcdid,cruId:cruId,ejcd:ejcd,topath:'xwzx'}}">
               <a href="#" class="curr1">{{curentName}}</a>
                </router-link>
                                        </span>
                                    </li>
                                    
                                </ul>
                                
                            </div>

    </div>
                    </div>
                    <div class="n_content_right">
                        <div class="n_content_right_name"></div>
                        <div class="n_content_right_m">

                       
                        

 <div v-html="showNr">
    
 </div>

 

                      

                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
 
 
	   
  </Layout>
</template>
   


 
 
<script>
    import Layout from '../components/common/Layout'
 import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'MyPage',
        components: {  Layout},
        data() {
            return {
                showNr:"",
                showZcdNr:"",
                 cruId:this.$route.params.cruId,
                 ejcd:this.$route.params.ejcd,
                 cruName:"",
                 zcdid:this.$route.params.zcdid,

    
            }
        },
         // 页面销毁之前
    beforeDestroy() {
        sessionStorage.removeItem('paramsData')
   	},
        created(){
         debugger;
             var params = [];
          if(this.$route.params.topath){//从菜单点进来-有参数，直接取菜单参数,并将参数放入缓存
               
                 params = this.$route.params;
	            sessionStorage.setItem("paramsData", JSON.stringify(params));
            }else{//从浏览器返回或者刷新-无参数,取session中的参数,并且每次session中的参数都要刷新
                  params = JSON.parse(sessionStorage.getItem("paramsData"));
            }
             this.zcdid = params.zcdid;
                 this.cruId = params.cruId;
                  this.ejcd = params.ejcd;


             
            if(this.ejcd.length==0){//
                this.querAll(this.cruId,'DB_DEMO_XW');
            }else{
                 
                this.cruName = this.cruId;
                
                this.querAllZcd(this.zcdid,'DB_DEMO_MENU');
                this.querAll(this.cruId,'DB_DEMO_XW');
            }
        },
        	methods:{
				querAllZcd(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showZcdNr=response.data.showNr;

        })
    },
            querAll(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showNr=response.data.showNr;

        })
    },
    // getListData(item){
     
    //      this.cruId = item.id;
    //      this.curentName=item.strName;
        
    //     this.querAll();
    // },
    getListData(tab){
          this.$router.push({
          name: 'empty',
          params: {
            zcdid: this.zcdid,
             cruId: tab.id,
              ejcd: this.ejcd,
             topath: 'xwzx'
          }
        })
       
    },
		},
		 
    }
</script>
 <style scoped  src="./企业新闻 - 建筑 - 住宅 - 重庆渝隆远大住宅工业有限公司_files/CgAGS10CCVKAEj9oAAA_RVsR5CE119.css">
 

 </style>