<template>
  <Layout :footer-style="2">
    <div v-html="showZcdNr">
    
 </div>
	   <div class="n_main">
                <div class="n_content">
                    <div class="n_content_left">
                    

                      


                            <div id="n_content_right_name_r" class="n_content_right_name_r" data-type="block">
                                <ul>
                                    <li>
                                        <span class="n_r_wz1">
                                            
                                            <span name="_n_r_wz1">您现在的位置：</span>
                                            
                                        </span>
                                       <span class="n_r_wz2">
                                            <a href="http://localhost:8080  ">
                                            <span name="_n_r_wz2">首页</span>
                                            </a>
                                        </span>
                                        <span class="n_r_wz3">→</span>
                                        <span class="n_r_wz4">
                                            <span id="ShoveWebControl_Text_case"><a href="http://localhost:8080/hylx" target="_self">行业领先</a></span>
                                        </span>
                                        <span class="n_r_wz5">→</span>
                                        <span class="n_r_wz6">
                                            <a href="http://localhost:8080/xmzszx" class="curr1">经典案例</a>
                                        </span>
                                    </li>
                                     
                                </ul>   
                                       
                            </div>

    
                    </div>
                    <div class="n_content_right">
                        <div class="n_content_right_name"></div>
                        <div class="n_content_right_m">

                            <div v-html="showNr" >
                                  
								
                            </div>

                            <!-- <div class="case_view_foot">

                                <div id="xn_c_casev_3_warp" class="xn_c_casev_3_warp" data-type="block">
                                   
                                    <div id="EIMS_C_3_1_ShowInfo" style="COLOR: red"></div>
                                    <div id="EIMS_C_3_1_box_div">
                                        <span id="EIMS_C_3_1_Upli" class="EIMS_C_3_1_page">上一篇：无</span><span id="EIMS_C_3_1_Downli" class="EIMS_C_3_1_page"><a href="http://www.cqylyd.com/case_view.aspx?TypeId=30&amp;Id=438&amp;FId=t4:30:4" id="EIMS_C_3_1_Down" title="恒大御峰">&nbsp;&nbsp;&nbsp;下一篇&nbsp;&nbsp;&nbsp;<span id="EIMS_C_3_1_DownNew">恒大御峰</span></a> 
                                        </span>
                                    </div>
                                    
                                    
                                    
                                </div>

                            </div> -->
                        </div> 
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
                    
 
 
	   
  </Layout>
</template>
   


 
 
<script>
    import Layout from '../components/common/Layout'
 import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'MyPage',
        components: {  Layout},
        data() {
            return {
                showNr:"",
                showZcdNr:"",
                  cruId:this.$route.params.cruId,
                //  ejcd:this.$route.params.ejcd,
                //  cruName:"",
                //  zcdid:this.$route.params.zcdid,

    
            }
        },
         // 页面销毁之前
    beforeDestroy() {
        sessionStorage.removeItem('paramsData')
   	},
        created(){
            var params = [];
          if(this.$route.params.topath){//从菜单点进来-有参数，直接取菜单参数,并将参数放入缓存
               
                 params = this.$route.params;
	            sessionStorage.setItem("paramsData", JSON.stringify(params));
            }else{//从浏览器返回或者刷新-无参数,取session中的参数,并且每次session中的参数都要刷新
                  params = JSON.parse(sessionStorage.getItem("paramsData"));
            }
             
                 this.cruId = params.cruId;
                
             this.querAllZcd('xmzs','DB_DEMO_MENU_DETAIL');
             this.querAll(this.cruId,'DB_DEMO_XMZS');
             
        },
        	methods:{
				querAllZcd(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showZcdNr=response.data.showNr;

        })
    },
            querAll(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showNr=response.data.showNr;

        })
    },
    getListData(tab){
       
        this.querAll(tab.name,"DB_DEMO_MENU_DETAIL");
    },
		},
		 
    }
</script>
 <style scoped  src="./广安帝谷公园城 - 建设 - 重庆渝隆远大住宅工业有限公司_files/CgAGS10CCU6AOkBiAAAswxkL6F4995.css">
 

 /* .n_content_left{width:100%;position:relative;overflow:hidden;background:url(https://1.rc.xiniu.com/rc/Contents/37038/images/gong_content_left.png?d=20190110141548786) left top repeat-x;min-height:182px;} */
 </style>