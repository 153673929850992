<template>
  <Layout :footer-style="2">
    <div v-html="showZcdNr">
    
 </div>
	   <div class="n_main">
                <div class="n_content">
                    <div class="n_content_left">
                    

                      


                            <div id="n_content_right_name_r" class="n_content_right_name_r" data-type="block">
                                <ul>
                                    <li>
                                        <span class="n_r_wz1">
                                            
                                            <span name="_n_r_wz1">您现在的位置：</span>
                                            
                                        </span>
                                        <span class="n_r_wz2">
                                           <router-link   to="/">
               <span name="_n_r_wz2">首页</span>
                </router-link>
                                        </span>
                                        <span class="n_r_wz3">→</span>
                                        <span class="n_r_wz4">
                                             
                                            <span id="ShoveWebControl_Text_case"><router-link  :to="{name:'empty',params: {zcdid:zcdid,cruId:cruId,ejcd:ejcd,topath:'hylx'}}">
               <a href="#" class="curr1">行业领先</a>
                </router-link></span>
                                        </span>
                                         
                                    </li>
                                     
                                </ul>   
                                       
                            </div>

    
                    </div>
                    <div class="n_content_right">
                        <div class="n_content_right_name"></div>
                         <div class="n_content_right_m">

    <div class="case_con_box1">
        <div class="case_con_box1_top">
            
            <span name="_case_con_box1_01">全产业链研发</span>
            
        </div>
        <div class="case_con_box1_bot">
            
            <span name="_case_con_box1_02">成为最具竞争力的建筑科技产业集团!</span>
            
        </div>
    </div>


    <div class="case_con_erjlb_wrap" id="case_con_erjlb_wrap" data-type="block">
        <div class="case_con_erjlb_inner">
            <ul class="case_con_erjlb_ul">
            
            </ul>
        </div>
          
    </div>


    <div id="n_case_m" class="n_case_m" data-type="block">
        <div class="n_case_m_wrap">
            
                    <div class="n_case_main n_case_main0" v-for="(item, index) in xmlist"  style="display: block;">
                        <div class="n_case_img">
                            <a target="_blank"  >
                           <router-link   :to="{name:'empty',params: {zcdid:item.id,cruId:item.id,ejcd:[],topath:'xmzsxq'}}">
                                                                	 <img :alt="item.FNAME" :title="item.FNAME" :src="item.FYL">
                                                             </router-link>
                           
                           </a>
                            


                           
                        </div>
                        <div class="n_case_m_con_box">
                            <div class="n_case_main_name">
                                <a  :title="item.FNAME" >
                                    <router-link   :to="{name:'empty',params: {zcdid:item.id,cruId:item.id,ejcd:[],topath:'xmzsxq'}}">
                                                                	 <span name="_n_case_m1390|T_News|Id|390|Name">{{item.FNAME}}</span>
                                                             </router-link>
                                    
                                    
                                </a>
                            </div>
                            <div class="n_case_main_cnt" ej="edit_span">项目地址：{{item.FADDS}}</div>
                        </div>
                    </div>
                 
            <div class="clear n_case_main8"></div>
            <div id="fy" class="fy">
                                    <div>
	 <el-pagination
     layout="prev, pager, next"
     @current-change="changePageNum"
     :current-page="pageNum"
     :page-size="pageSize"
     :total="total">
</el-pagination>
</div>
                                    
                                </div>
        </div>
        
    </div>

                        </div>
                    </div>
                    
                    <div class="clear"></div>
                </div>
            </div>
                    
 
 
	   
  </Layout>
</template>
   


 
 
<script>
    import Layout from '../components/common/Layout'
   
     
 import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'MyPage',
        components: {  Layout},
        data() {
            return {
                 total: 0,//总数
        pageNum: 1,//当前页
        pageSize: 8,//每页显示数量
                showNr:"",
                showZcdNr:"",
                xmlist:[],
                //  cruId:this.$route.params.cruId,
                //  ejcd:this.$route.params.ejcd,
                //  cruName:"",
                //  zcdid:this.$route.params.zcdid,

    
            }
        },
        created(){
             this.querAllZcd('xmzs','DB_DEMO_MENU_DETAIL');
             this.querAll('16588466803510100000','DB_DEMO_XMZS');
             this.querXmzs();
        },
        	methods:{
				querAllZcd(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showZcdNr=response.data.showNr;

        })
    },
            querAll(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showNr=response.data.showNr;

        })
    },
    querXmzs(){
					debugger;
					var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/xmzs/card/custom?service=getXmlist&&pageNum='+this.pageNum+'&pageSize='+this.pageSize, null).then(function (response) {
         
      self.xmlist=response.data.xMzxRet.xwmx;
    self.total=response.data.xMzxRet.total;
      
        })
        },
         changePageNum: function (val) {
        this.pageNum = val;
        
        this.querXmzs();
      },
    getListData(tab){
       
        this.querAll(tab.name,"DB_DEMO_MENU_DETAIL");
    },
		},
		 
    }
</script>
 <style scoped  src="./案例中心 - 住宅 - 建筑 - 重庆渝隆远大住宅工业有限公司_files/CgAGe2DZFzmALUsyAAA3O3WbImI394.css">
  
 
 .n_content_left{width:100%;position:relative;overflow:hidden;background:url(https://1.rc.xiniu.com/rc/Contents/37038/images/gong_content_left.png?d=20190110141548786) left top repeat-x;min-height:182px;}
 </style>