<template>
  <Layout :footer-style="2">
    <div v-html="showZcdNr">
    
 </div>
  <div id="ea_c">
            <div class="n_main">
                <div class="n_content">
                    <div class="n_content_left">
                    <div class="n_content_left_wrap">

                        <div id="n_content_left_cnt" class="n_content_left_cnt">
                            <ul id="ShoveWebControl_VMenu2" class="left_typeUl left_typeUl_n000" style="display: block;">
<ul id="left_typeUl_t200" class="left_typeUl left_typeUl_t200">

<li  v-for="item in ejcd"  id="left_typeLi_t200_1" data_id="2_5" :class="{'left_menu1_cur': cruId===item.id, 'left_menu1': cruId!=item.id}" type="t" index="1" level="0"  @click="getListData(item)" ><a  href="#" target="_self"><span name="ShoveWebControl_VMenu21654180832|T_NewsTypes|Id|5|Name">{{item.strName}}</span></a>
</li>

</ul>
</ul>
                       
                        </div>


                            <div id="n_content_right_name_r" class="n_content_right_name_r"> 
                                <ul>
                                    <li><span class="n_r_wz1">
                                        
                                        <span name="_n_r_wz1">您现在的位置：</span>
                                        
                                    </span><span class="n_r_wz2">
                                        
                                            <router-link   to="/">
               <span name="_n_r_wz2">首页</span>
                </router-link>
                                    </span><span class="n_r_wz3">→</span>
                                   <span class="n_r_wz4">
                                            <span id="ShoveWebControl_Text_news">
                                                 <router-link  :to="{name:'empty',params: {zcdid:zcdid,cruId:cruId,ejcd:ejcd,topath:'xwzx'}}">
               <a href="#" class="curr1">新闻中心</a>
                </router-link>
                                               
                                              </span>
                                        </span>
                                        <span class="n_r_wz5">→</span>
                                        <span class="n_r_wz6">
                                           
                                              <router-link   :to="{name:'empty',params: {zcdid:zcdid,cruId:cruId,ejcd:ejcd,topath:'xwzx'}}">
               <a href="#" class="curr1">{{curentName}}</a>
                </router-link>
                                        </span>
                                    </li>
                                    
                                </ul>
                                
                            </div>

    </div>
                    </div>
                    <div class="n_content_right">
                        <div class="n_content_right_name"></div>
                        <div class="n_content_right_m">

                        <div id="xn_c_100_wrap" class="xn_c_100_wrap">
 
          <swiper class="xn_c_100_wrap" :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in xwlist" :key="index">
             <div class="xn_c_100_img">
	                                                <a :title="item.FTITLE"  target="_blank">
	                                                   <router-link   :to="{name:'xwxq',params: {zcdid:zcdid,cruId:item.id,ejcd:ejcd,topath:'xwxq'}}"> 
                                                           <img  :alt="item.FTITLE" :title="item.FTITLE" :src="item.FTPDZ">
                                                </router-link>
                                                    </a>
	                                            </div>
                                                <div class="xn_c_100_con">
	                                                <div class="xn_c_100_Title">
	                                                    <a :title="item.FTITLE" target="_blank">
	                                                         <router-link   :to="{name:'xwxq',params: {zcdid:zcdid,cruId:item.id,ejcd:ejcd,topath:'xwxq'}}">
                                                                	{{item.FTITLE}}
                                                             </router-link>
	                                                    </a>
	                                                </div> 
                                                    <div class="xn_c_100_time">
                                                        <span class="xn_c_100_newsYear"> 
                                                            <span id="xn_c_100_data_xn_c_100_newsYear_0">{{item.FDATE}}</span>
                                                        </span> 
                                                        
                                                    </div>                                     
	                                               <div class="xn_c_100_context" ej="edit_span">{{item.FDESC}}</div>                                   
	                                            </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
         
         </div>
                        


                        <div id="xn_c_news_18_wrap" class="xn_c_news_18_wrap">
                            
                            <div class="xn_c_news_18_wcoentbox" v-for="(item, index) in xwlist" :key="index">
                                
                                        <div class="xn_c_news_18_xhcoentbox">
                                            <div class="xn_c_news_18_lftbox">
                                                <a :title="item.FTITLE"   target="_blank">
                                                    <router-link   :to="{name:'xwxq',params: {zcdid:zcdid,cruId:item.id,ejcd:ejcd,topath:'xwxq'}}">
                                                                	  <img :alt="item.FTITLE" :title="item.FTITLE" :src="item.FTPDZ">
                                                             </router-link>
                                                  
                                                </a>
                                            </div>
                                            <div class="xn_c_news_18_ritbox">
                                                <div class="xn_c_news_18_title">
                                                    <a :title="item.FTITLE"   target="_blank">
                                                       <router-link   :to="{name:'xwxq',params: {zcdid:zcdid,cruId:item.id,ejcd:ejcd,topath:'xwxq'}}">
                                                                	   {{item.FTITLE}}
                                                             </router-link>
                                                       
                                                    </a>
                                                </div>
                                                <div class="xn_c_news_18_timebox">
                                                    <span class="xn_c_news_18_newsYear"> 
                                                        <span id="xn_c_news_18_Data_xn_c_news_18_newsYear_0">{{item.FDATE}}</span>
                                                    </span> 
                                                     
                                                </div>
                                                <div class="xn_c_news_18_coent" ej="edit_span"></div>
                                                <div class="xn_c_news_18_more">
                                                    <router-link   :to="{name:'xwxq',params: {zcdid:zcdid,cruId:item.id,ejcd:ejcd,topath:'xwxq'}}">
                                                    <a :title="item.FTITLE"   target="_blank">
                                                        
                                                       
                                                        
                                                                	   <span name="_news_18_more">{{item.FDESC}}</span>
                                                            
                                                    </a>
                                                     </router-link>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                            
                            
                        </div>

<div id="fy" class="fy">
                                    <div>
						 <el-pagination
     layout="prev, pager, next"
     @current-change="changePageNum"
     :current-page="pageNum"
     :page-size="pageSize"
     :total="total">
</el-pagination>
                            </div>
                              </div>


                        <div class="xn_c_news_15_wrap" id="xn_c_news_15_wrap">
                            
                            <div class="xn_c_news_15_wbox_top">
                                
                                    <span name="_news_15_title">推荐新闻</span>
                                
                            </div>
                             <div class="xn_c_news_15_wbox"    >
                                 <div class="xn_c_news_15_list" v-for="(item, index) in xwlist" :key="index">
                                           
                                            <div class="xn_c_news_15_content">
                                                <div class="xn_c_news_15_title">
                                                    <a     target="_blank">
                                                      <router-link   :to="{name:'xwxq',params: {zcdid:zcdid,cruId:item.id,ejcd:ejcd,topath:'xwxq'}}">
                                                                	   {{item.FTITLE}}
                                                             </router-link>
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    
                                       
                                    
                            </div>
                            
                            
                        </div>

                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
                  
                   
                
 
	   
  </Layout>
</template>
   


 
 <script  src="./企业新闻 - 建筑 - 住宅 - 重庆渝隆远大住宅工业有限公司_files/jqueryV173.js.下载"   ></script>
 <script  src="./企业新闻 - 建筑 - 住宅 - 重庆渝隆远大住宅工业有限公司_files/Public.js.下载"   ></script>
 <script  src="./企业新闻 - 建筑 - 住宅 - 重庆渝隆远大住宅工业有限公司_files/swfobject_modified.js.下载"   ></script>
 <script  src="./企业新闻 - 建筑 - 住宅 - 重庆渝隆远大住宅工业有限公司_files/swfobject.js.下载"  ></script>
 <script  src="./企业新闻 - 建筑 - 住宅 - 重庆渝隆远大住宅工业有限公司_files/CgAGe1x4nu-AYzMSAAArST5L1us4018.js.下载"  ></script>
<script>
 import { swiper, swiperSlide } from "vue-awesome-swiper";
  import "swiper/dist/css/swiper.css";
// import "./企业新闻 - 建筑 - 住宅 - 重庆渝隆远大住宅工业有限公司_files/CgAGS10CCVKAEj9oAAA_RVsR5CE119.css"
 
    import Layout from '../components/common/Layout'
 import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'MyPage',
        components: {  Layout,swiper,swiperSlide},
       
        data() {
            return {
              curentName:"媒体报道",
                total: 0,//总数
        pageNum: 1,//当前页
        pageSize: 10,//每页显示数量
                swiperOption: {
         autoplay: true,
         speed: 300,
         loop: true,
         pagination: {
          el: ".swiper-pagination", //分页器
          clickable: true,
         },
	  },
				xwlist:[],
                showNr:"",
                showZcdNr:"",
                 cruId:this.$route.params.cruId,
                 ejcd:this.$route.params.ejcd,
                 cruName:"",
                 zcdid:this.$route.params.zcdid,

    
            }
        },
         // 页面销毁之前
    beforeDestroy() {
        sessionStorage.removeItem(this.$route.name+'paramsData')
   	},
        created(){
           
             var params = [];
          if(this.$route.params.zcdid){//从菜单点进来-有参数，直接取菜单参数,并将参数放入缓存
               
                 params = this.$route.params;
	            sessionStorage.setItem(this.$route.name+"paramsData", JSON.stringify(params));
            }else{//从浏览器返回或者刷新-无参数,取session中的参数,并且每次session中的参数都要刷新
                  params = JSON.parse(sessionStorage.getItem(this.$route.name+"paramsData"));
            }
             this.zcdid = params.zcdid;
                 this.cruId = params.cruId;
                  this.ejcd = params.ejcd;
             this.cruName = this.cruId;
             this.querAllZcd(this.zcdid,'DB_DEMO_MENU');//查询主菜单内容--顶部图片
             this.querAll();//根据页签（新闻类型）分页查询新闻明细
        },
        	methods:{
                //切换页码
        changePageNum: function (val) {
        this.pageNum = val;
        
        this.querAll();
      },
				querAllZcd(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showZcdNr=response.data.showNr;

        })
    },
            querAll() {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/xw/card/custom?service=getxwzxXw&&ftypeid='+this.cruId+'&&pageNum='+this.pageNum+'&pageSize='+this.pageSize, null).then(function (response) {
         
          self.xwlist=response.data.xwRet.xwmx;
          self.total=response.data.xwRet.total;
        //  self.$refs.mySwiper.reset();
        })
    },
    getListData(item){
     
         this.cruId = item.id;
         this.curentName=item.strName;
        
        this.querAll();
    },
		},
		 
    }
</script>
 <style scoped  src="./企业新闻 - 建筑 - 住宅 - 重庆渝隆远大住宅工业有限公司_files/CgAGS10CCVKAEj9oAAA_RVsR5CE119.css">
 

 </style>