<template>
  <Layout :footer-style="2">
    <div v-html="showZcdNr">
    
 </div>
	 <div id="ea_c">
            <div class="my_main">
                <div class="my_content">
                    <div class="my_content_left">
						

        <div id="bk2_my_content_right_name_r" class="bk2_my_content_right_name_r" data-type="block" data-from="favorite" data-id="34130" data-index="2">
            <ul>
                <li>
                    <span class="bk2_my_r_wz1">
                        
                        <span name="bk2__my_r_wz1">您现在的位置：</span>
                        
                    </span>
                    <span class="bk2_my_r_wz2">
                        <a href="#">
                        <span name="bk2__my_r_wz2"><router-link   to="/">
                                                                	    首页
                                                             </router-link></span>
                        </a>
                    </span>
                    <span class="bk2_my_r_wz3">→</span>
                    <span class="bk2_my_r_wz4">
                        <span id="bk2_ShoveWebControl_Text_about"><a href="#" target="_self">来访预约</a></span>
                    </span>
                    <span class="bk2_my_r_wz5">→</span>
                    <span class="bk2_my_r_wz6">
                        
                    </span>
                </li>
                
            </ul>
            
        </div>


                        <div id="my_content_left_t" class="my_content_left_t" data-type="block">
                            <span class="my_content_left_t1">
                                <span id="ShoveWebControl_Text21">来访预约</span>
                            </span><span class="my_content_left_t2">
                                <span name="_nleft_my_name1">Contact us</span>
                            </span>
							
                        </div>
                        
                    </div>
                    <div class="my_content_right">
                        <div class="my_content_right_name">
                            
                        </div>
                        <div class="my_content_right_m">
                            

                                                            <div id="bk1_xmy_c_contact_1_wrap" class="bk1_xmy_c_contact_1_wrap" data-type="block" data-from="favorite" data-id="30835" data-index="1">
                                                                <div id="bk1_EIMS_C_1_4Panel" class="bk1_EIMS_C_1_4_box">
                                                                 
                                                                    <div class="bk1_xmy_c_contact_1_feedback">
                                                                        
                                                                        <div class="EIMS_C_1_4_box">
                                                                            <div id="bk1_EIMS_C_1_4_Show" class="bk1_EIMS_C_1_4_DIV">
                                                                                <ul class="contfeed_ulname">
                                                                                    <li class="contfeed_namewz">
                                                                                        
                                                                                        <span name="bk1__contfeed_namewz">
                                                                                        姓名
                                                                                        </span>
                                                                                        
                                                                                    </li> 
                                                                                    <li class="contfeed_nametex">
                                                                                        <input name="bk1_EIMS_C_1_4_Name" type="text" id="bk1_EIMS_C_1_4_Name" foundfield="" compareway="2">
                                                                                        <b class="bt">*</b> 
                                                                                    </li>
                                                                                </ul>
                                                                                <ul class="bk1_contfeed_ulcomp">
                                                                                    <li class="contfeed_compwz">
                                                                                        <span name="bk1__contfeed_compwz">电话</span>
                                                                                    </li> 
                                                                                    <li class="contfeed_comptext">
                                                                                        <input name="bk1_EIMS_C_1_4_Company" type="text" id="bk1_EIMS_C_1_4_Company" foundfield="" compareway="2">
                                                                                        <b class="bt">*</b> 
                                                                                    </li>
                                                                                </ul>
                                                                                <ul class="contfeed_uladds">
                                                                                    <li class="contfeed_addswz">
                                                                                        
                                                                                        <span name="bk1__contfeed_addswz">
                                                                                        地址：
                                                                                        </span>
                                                                                        
                                                                                    </li> 
                                                                                    <li class="contfeed_addstex">
                                                                                        <input name="bk1_EIMS_C_1_4_Address" type="text" id="bk1_EIMS_C_1_4_Address" foundfield="" compareway="2">
                                                                                        <b class="bt">*</b> 
                                                                                    </li>
                                                                                </ul>
                                                                                <ul class="contfeed_ultel">
                                                                                    <li class="contfeed_telwz">
                                                                                        <span name="bk1__contfeed_telwz">
                                                                                        公司</span>
                                                                                    </li> 
                                                                                    <li class="contfeed_teltext">
                                                                                        <input name="bk1_EIMS_C_1_4_Tel" type="text" id="bk1_EIMS_C_1_4_Tel" foundfield="" compareway="2">
                                                                                        <b class="bt">*</b> 
                                                                                    </li>
                                                                                </ul>
                                                                                <ul class="bk1_contfeed_ulfax">
                                                                                    <li class="contfeed_faxwz">
                                                                                        
                                                                                        <span name="bk1__contfeed_faxwz">
                                                                                        传真：
                                                                                        </span>
                                                                                        
                                                                                    </li> 
                                                                                    <li class="contfeed_faxtext">
                                                                                        <input name="bk1_EIMS_C_1_4_Fax" type="text" id="bk1_EIMS_C_1_4_Fax" foundfield="" compareway="2">
                                                                                        <b class="bt">*</b> 
                                                                                    </li>
                                                                                </ul>
                                                                                <ul class="contfeed_ulmail">
                                                                                    <li class="contfeed_mailwz">
                                                                                        <span name="bk1__contfeed_mailwz">
                                                                                        职位</span>
                                                                                    </li> 
                                                                                    <li class="contfeed_mailtext">
                                                                                        <input name="bk1_EIMS_C_1_4_Email" type="text" id="bk1_EIMS_C_1_4_Email" foundfield="" compareway="2">
                                                                                        <b class="bt">*</b> 
                                                                                    </li>
                                                                                </ul>
                                                                                <ul class="bk1_contfeed_ulpost">
                                                                                    <li class="contfeed_postwz">
                                                                                        
                                                                                        <span name="bk1__contfeed_postwz">
                                                                                        邮政编码：
                                                                                        </span>
                                                                                        
                                                                                    </li> 
                                                                                    <li class="contfeed_posttext">
                                                                                        <input name="bk1_EIMS_C_1_4_asdfasdfl" type="text" id="bk1_EIMS_C_1_4_asdfasdfl" foundfield="" compareway="2">
                                                                                        <b class="bt">*</b> 
                                                                                    </li>
                                                                                </ul>
                                                                                <ul class="bk1_contfeed_ultitle">
                                                                                    <li class="contfeed_titlewz">
                                                                                        
                                                                                        <span name="bk1__contfeed_titlewz">
                                                                                        留言主题：
                                                                                        </span>
                                                                                        
                                                                                    </li> 
                                                                                    <li class="contfeed_titletext">
                                                                                        <input name="bk1_EIMS_C_1_4_Title" type="text" id="bk1_EIMS_C_1_4_Title" foundfield="" compareway="2">
                                                                                        <b class="bt">*</b> 
                                                                                    </li>
                                                                                </ul>
                                                                                <ul class="bk1_contfeed_ulcont">
                                                                                    <li class="contfeed_contwz">
                                                                                        
                                                                                        <span name="bk1__contfeed_contwz">
                                                                                    人数
                                                                                        </span>
                                                                                    </li> 
                                                                                    <li class="contfeed_conttext">
                                                                                        <textarea name="bk1_EIMS_C_1_4_Content" rows="2" cols="20" id="bk1_EIMS_C_1_4_Content" class="bk1_inputk" foundfield="" compareway="2" style="height:83px;"></textarea>
                                                                                        <b class="bt">*</b> 
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="bk1_EIMS_C_1_4_Check_box">
                                                                        
                                                                    </div>
                                                                    <div class="bk1_online_but">
                                                                           <!-- <el-button type='waring' id="bk1_EIMS_C_1_4_Submit">提交</el-button> -->
 
                                                                            <input type="button"  value="提交" @click="mysubmit"   id="bk1_EIMS_C_1_4_Submit"> 
                                                                        <input type="button" name="bk1_EIMS_C_1_4_Reset"  @click="myreset" value="重置" onclick="this.form.reset();return false;" id="bk1_EIMS_C_1_4_Reset">
                                                                    </div>
                                                                </div>
                                                                     
                                                                    
                                                                    
                                                            </div>
                                
<a href="https://www.amap.com/search?query=%E9%87%8D%E5%BA%86%E9%9A%86%E5%AF%8C%E8%BF%9C%E5%A4%A7%E5%BB%BA%E7%AD%91%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&city=500000&geoobj=107.126308%7C29.174332%7C107.132376%7C29.177188&zoom=17.5" target="_blank">
                            <div id="lxwm_cont" class="lxwm_cont" data-type="block">
                                <span id="ShoveWebControl_Text2"><p><img   style="max-width:100%;" src="./来访预约 - 住宅 - 装配式 - 重庆渝隆远大住宅工业有限公司_files/1659080815465.jpg"></p></span>
								
                            </div>
                          </a>
                            
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
 
	   
  </Layout>
</template>
   


 
 
<script>
    import Layout from '../components/common/Layout'
     
    
 import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'lfyy',
        components: {  Layout},
        data() {
            return {
                
                showZcdNr:"",
                 
                 

    
            }
        },
        created(){
              
           this.querAllZcd('16590186098760100000','DB_DEMO_MENU');
            //this.querAll(this.zwid,'DB_DEMO_ZPZW');// 分页查询职位详情
        },
        	methods:{
            mysubmit(){
                 var self = this;
                var xm = document.getElementById("bk1_EIMS_C_1_4_Name").value;
                var dh = document.getElementById("bk1_EIMS_C_1_4_Company").value;
                var gs = document.getElementById("bk1_EIMS_C_1_4_Tel").value;
                var zw = document.getElementById("bk1_EIMS_C_1_4_Email").value;
               var rs = document.getElementById("bk1_EIMS_C_1_4_Content").value;
              
                 var param = {"xm":xm,
                 "dh":dh,
                 "gs":gs,
                 "zw":zw,
                 "rs":rs,
       
        };
        var headers = {
          'Content-Type': 'multipart/form-data'
        } 
      
        jjService.reportbasedata(jjService.apisrc+'/plugin/demo/lfyy/card/custom?service=saveTodo&xm='+xm+'&dh='+dh+'&gs='+gs+'&zw='+zw+'&rs='+rs, param,headers).then(function (response) {
       
     alert("预约成功");
   self.myreset();
     
     });


            },
            myreset(){
                document.getElementById("bk1_EIMS_C_1_4_Name").value="";
                document.getElementById("bk1_EIMS_C_1_4_Company").value="";
                document.getElementById("bk1_EIMS_C_1_4_Tel").value="";
                document.getElementById("bk1_EIMS_C_1_4_Email").value="";
                document.getElementById("bk1_EIMS_C_1_4_Content").value="";
            },
				querAllZcd(fid,tableName) {
      
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showZcdNr=response.data.showNr;

        })
    },
            
		},
		 
    }
</script>
 <style scoped  >
 

@charset "utf-8";
html, body, div, p, ul, ol, li, dl, dt, dd, h1, h2, h3, h4, h5, h6, form, input, select, button, textarea, iframe, table, th, td {margin: 0; padding: 0; }
img { border: 0 none; vertical-align: top; }
ul,li,dl,dd,dt { list-style-type: none; }
i,em,cite { font-style: normal; }
body {  -webkit-text-size-adjust:none;  font-family:"微软雅黑";}
a{ text-decoration: none; }
.clear,.cle {clear:both;}
a,area { blr:expression(this.onFocus=this.blur()) }   
a:focus { outline: none; }    



.t_logo{ position:absolute; top:15px; left:0px;}
.t_logo img{height:70px;border:0px;width: 240px;}
#ea_l{position:relative;top:0px;left:0px;width: 240px;height:70px;}


.xmy_my_14_wrap{width:1200px; height:0px; background:#fff; position:relative; z-index:999; margin:auto;}
.xmy_my_14_wrap_main{margin:0 auto; width:1050px; height:40px; margin:auto; position:absolute; right:0px; top:-70px; z-index:999}
.xmy_my_14_wrap_main>ul{width:100%; height:40px;text-align:right;}
.xmy_my_14_wrap_main>ul>li{height:40px; width:115px;position:relative; display:inline-block;*zoom:1;*display: inline; text-align: center; vertical-align:top;}
.xmy_my_14_wrap_main>ul>li>a{color: #474747; width:115px; font-size:18px;display: block;height: 40px; line-height:40px; border-radius:50px; }
 .xmy_my_14_wrap_main>ul>li>a:hover,.xmy_my_14_wrap_main>ul>li.xmy_my_14_wrap_menu1_2>a,.xmy_my_14_wrap_main>ul>li.xmy_my_14_wrap_menu1_cur>a{color: #ffffff; background:#1C8CFF; }
.xmy_my_14_wrap_main>ul>li>ul{width: 100%; text-align:center;  height: auto;position:absolute;top:40px;left:0px; background:rgba(255,255,255,0.8); *background:url(https://1.rc.xiniu.com/rc/Heads/48483/images/nav_bj.png?d=20190312104536746) repeat;padding:10px 0px;}
.xmy_my_14_wrap_main>ul>li>ul>li{line-height:40px;font-size:18px; height:40px;margin: 0 auto;}
.xmy_my_14_wrap_main>ul>li>ul>li>a{color:#474747;  margin:auto;display:block;}
.xmy_my_14_wrap_main>ul>li>ul>li>a:hover{color:#1C8CFF;}
.xmy_my_14_wrap_main>ul>li>ul>li>ul{display:none!important}
.case_body #type1Li_n3>a ,.case_view_body  #type1Li_n3>a{color: #ffffff; background:#1C8CFF; }


.top{ width: 100%; z-index:99; position:relative; background: url(https://1.rc.xiniu.com/rc/Heads/48483/Images/ihpsp1_top_bg.gif?d=20190312104536746) repeat-x; }
.t_t{position:relative; z-index:100; width:1200px; margin:0 auto; height:100px;}




.news_banner{width:100%;overflow: hidden; position:relative; margin:0 auto;}
.news_banner_m{}
.news_banner_m>img{width:100%;height:100%;}




.bk2_my_content_right_name_r{float:right;}
.bk2_my_r_wz1,.bk2_my_content_right_name_r>ul>li>img{display:none;}
.bk2_my_r_wz1,.bk2_my_r_wz2,.bk2_my_r_wz3,.bk2_my_r_wz4,.bk2_my_r_wz5,.bk2_my_r_wz6{}
.bk2_my_content_right_name_r>ul>li{float:right;height:80px;line-height:80px;}
.bk2_my_content_right_name_r>ul>li>span{float:left;}
.bk2_my_content_right_name_r>ul>li>span a{font-size:12px;color:#303030;float:left;}
.bk2_my_content_right_name_r>ul>li>span a:hover{color:#1d2e66;}
.bk2_my_r_wz3,.bk2_my_r_wz5{font-size:0;display:block;width:28px;background:url(https://1.rc.xiniu.com/rc/Contents/37053/images/right_name_r_01.png?d=20190110141548880) center no-repeat;float:left;}



.xmy_c_contact_1_wrap_zx{width: 1200px;margin: 0 auto;}
.xmy_c_contact_1_wrap_zx_img{background: url(https://1.rc.xiniu.com/rc/Contents/14183/images/xmy_c_contact_1_wrap_zx_img.png?d=20190111121501529) no-repeat center;width: 1200px;height: 237px;margin-top: 95px;margin-bottom: 56px;display: none;}
.bk1_xmy_c_contact_1_wrap {width: 900px;height: auto;overflow: hidden;margin: 0 auto;padding-top: 60px;}
.bk1_xmy_c_contact_1_feedback{width: 100%;height: auto;overflow: hidden;}
.bk1_EIMS_C_1_4_DIV ul{float: left;width: 450px;height: 38px;margin-bottom: 20px;}
.bk1_EIMS_C_1_4_DIV ul li{float: left;}
.bk1_EIMS_C_1_4_DIV ul li span{width: 55px;line-height: 38px;height: 38px;text-align: center;font-size: 14px;color: #6c6c6c;display: block;}
.bk1_EIMS_C_1_4_DIV ul li input{width: 325px;height: 36px;line-height: 36px;border: 1px solid #dddddd;background: none;text-indent: 10px;font-size: 14px;color: #6c6c6c;font-family: "微软雅黑";outline: none;}
.bk1_EIMS_C_1_4_DIV ul li b{display: none;}
.bk1_contfeed_ulcomp,.bk1_contfeed_ulfax,.bk1_contfeed_ulpost,.bk1_contfeed_ultitle{}
.contfeed_uladds,.bk1_contfeed_ulfax,.bk1_contfeed_ulpost,.bk1_contfeed_ultitle{display: none;}
.bk1_EIMS_C_1_4_DIV .bk1_contfeed_ulcont{width: 900px;clear: both;height: 120px;}
.bk1_EIMS_C_1_4_DIV ul li #bk1_EIMS_C_1_4_Content{width: 775px;height: 117px!important;border: 1px solid #dddddd;background: none;text-indent: 10px;font-size: 14px;color: #6c6c6c;font-family: "微软雅黑";outline: none;}
.bk1_EIMS_C_1_4_Check_box{display: none;}
.bk1_online_but{width: 900px;height: 42px;margin: 22px auto 0 auto;clear: both;}
.bk1_online_but input{width: 362px;*width: 356px;height: 42px;margin-left: 55px;*margin-left: 65px;line-height: 42px;font-size: 16px;float: left;text-align: center;border: none;font-family: "微软雅黑";color: #fff;cursor: pointer;outline: none;}
#bk1_EIMS_C_1_4_Submit{background: #D02223;}
#bk1_EIMS_C_1_4_Submit:hover{background: #A70A0A;}
#bk1_EIMS_C_1_4_Reset{background: #AFAFAF;}
#bk1_EIMS_C_1_4_Reset:hover{background: #838383;}


.my_main{width: 100%; margin:0; padding:0;height:auto; overflow:hidden;z-index:5; position: relative;} 
.my_content{width:1000px; margin:0 auto;}


.my_content_left{width:100%; height:113px; position:relative;}


.my_content_right{position:relative; width:100%; height:auto;}


.my_content_right_name{display:block;}


.my_content_right_m {width:100%;margin:0 auto;line-height:24px;color:#737373;font-size:12px;padding:30px 0 20px 0;position:relative;}


.my_content_left_t{width:100%; height:126px; background: url(https://1.rc.xiniu.com/rc/Contents/7691/images/icpsp1_my_left_t.png?d=20190115152656868) no-repeat bottom center;font-size:16px;text-align:center;line-height:40px;padding-top:38px;}
.my_content_left_t1{display:block;width:100%;height:42px;margin-top:30px;line-height:42px;text-align:center;font-size:26px;color:#3e466b;overflow:hidden;}
.my_content_left_t2{display:inline-block;*zoom:1;*display:inline; height:27px;line-height:27px;text-align:center;font-size:12px;color:#3e466b;text-transform:uppercase;max-width:800px;padding:0 10px;overflow:hidden;background:#FFF;}


.my_content_left_cnt{width:1000px; height:48;padding-top:24px;line-height:48px;text-align:center;font-size:19px;}
.my_content_left_cnt ul li ul li ul{border-bottom:none !important;}
.left_menu1 {height:48px;padding:0 30px;max-width:140px;display:inline-block;*zoom:1;*display:inline;background:#6676A0;text-align:center;overflow:hidden;margin:0 3px;}
.left_menu1:hover{background:#699787;}
.left_menu1 a{color:#154a9a;}
.left_menu1_2 {margin:0 auto;width:193px;  background: #FFFFFF; font-weight:bold; font-size:13px; font-weight:bold; margin-bottom:6px;}
.left_menu1_cur{height:48px;padding:0 30px;max-width:140px;display:inline-block;*zoom:1;*display:inline;background:#154a9a;text-align:center;overflow:hidden;}
.left_menu1_cur a{color:#ffffff;}
.left_menu2{display:none;}


.xmy_c_1_newsWrap{border:1px solid #ccc;width:220px;}
.xmy_c_1_tName{padding-left:10px;height:45px;line-height:45px;background:#ccc;font-size:16px;font-family:Microsoft YaHei;}
.xmy_c_1_items{font-size:14px;font-family:Microsoft YaHei;margin:10px 12px;border-bottom:1px solid #ccc;padding-bottom:10px;}
.xmy_c_1_items:last-child{border:none;}
.xmy_c_1_ptime{font-size:12px;}
.xmy_c_1_title{margin-bottom:10px;overflow:hidden;text-overflow:ellipsis;width:200px;white-space:nowrap;}


.my_content_right_name_l{width:1000px;height:101px;background: url(https://1.rc.xiniu.com/rc/Contents/7691/images/icpsp1_my_left_t.png?d=20190115152656868) no-repeat bottom center;overflow:hidden;text-align:center;}
.my_content_right_name_l1{display:none;}
.my_content_right_name_l2{display:inline-block;*zoom:1;*display:inline;height:54px;margin-top:42px;line-height:54px;color:#565d7e;font-size:28px;background:#FFF;padding:0 10px;}
.pro_body .my_content_right_name_l{height:31px;background: url(https://1.rc.xiniu.com/rc/Contents/7691/images/icpsp1_my_left_t1.png?d=20190115152656868) no-repeat bottom center;margin-top:35px;}
.pro_body .my_content_right_name_l2{height:31px;line-height:29px;font-size:25px;color:#3e466b;font-weight:bold;margin-top:0px;}


.fy{ width:100%; padding-top:27px;font-size:12px; text-align:center; font-family:Arial;margin-bottom:50px;}
.fy span{display:inline-block;*zoom:1;*display:inline;width:39px;height:33px;margin:0 2px;background:#687A9F;}
.fy span:hover{background:#699787;}
.fy span a{width:100%;height:100%;color:#ffffff;float:left;line-height:33px;font-size:20px;}
.fy_1{}
.fy_2{background:url(https://1.rc.xiniu.com/rc/Contents/7691/images/fy_2bg.png?d=20190115152656868) no-repeat !important;text-indent:-9999px;width:46px !important;height:33px !important;}
.fy_3{margin-left:5px; margin-right:5px}
.fy_4{}
.fy_5{margin-left:5px; margin-right:5px}
.fy_6{background:url(https://1.rc.xiniu.com/rc/Contents/7691/images/fy_6bg.png?d=20190115152656868) no-repeat !important;text-indent:-9999px;width:46px !important;height:33px !important;}
.fy_7{ margin-left:5px; margin-right:5px}
.fy_8{}
.fy_9{}
.fy_out{}
.fy_on{background:#699787 !important;}
.search_body .fy{margin:40px 0 50px 0; border-top:1px solid #E5E5E5;}
.fy_1,.fy_9,.fy_4,.fy_7,.fy_3,.fy_5{display:none !important;}


.xmy_c_4_wrap{width:220px;margin:10px auto;font-size:12px;}
.xmy_c_4_wbox{width:220px;}
.xmy_c_4_top{width:220px;line-height:30px;}
.xmy_c_4_cocnt{width:220px;}
.xmy_c_4_cocntlft{float:left;width:80px;}
.xmy_c_4_cocntlft img{width:80px;height:100px;}
.xmy_c_4_cocntrit{float:left;width:140px;}
.xmy_c_4_cocntrit_name{width:140px;line-height:20px;}
.xmy_c_4_cocntrit_time{width:140px;line-height:20px;}
.xmy_c_4_cocntrit_cont{width:140px;height:40px;line-height:20px;overflow:hidden;}
.xmy_c_4_cocntrit_more{width:140px;}


.xmy_c_5_wrap{width:220px;margin:10px auto;font-size:12px;}
.xmy_c_5_wbox{width:220px;}
.xmy_c_5_top{width:220px;line-height:30px;}
.xmy_c_5_cocnt{width:220px;}
.xmy_c_5_cocntlft{float:left;width:80px;}
.xmy_c_5_cocntlft img{width:80px;height:100px;}
.xmy_c_5_cocntrit{float:left;width:140px;}
.xmy_c_5_cocntrit_name{width:140px;line-height:20px;}
.xmy_c_5_cocntrit_time{width:140px;line-height:20px;}
.xmy_c_5_cocntrit_Click{width:140px;}
.xmy_c_5_cocntrit_cont{width:140px;height:40px;line-height:20px;overflow:hidden;}
.xmy_c_5_cocntrit_more{width:140px;}


.xmy_c_6_wrap{width:220px;margin:10px auto;font-size:12px;}
.xmy_c_6_wbox{width:220px;}
.xmy_c_6_top{width:220px;line-height:30px;}
.xmy_c_6_cocnt{width:220px;}
.xmy_c_6_cocntlft{float:left;width:80px;}
.xmy_c_6_cocntlft img{width:80px;height:100px;}
.xmy_c_6_cocntrit{float:left;width:140px;}
.xmy_c_6_cocntrit_name{width:140px;line-height:20px;}
.xmy_c_6_cocntrit_time{width:140px;line-height:20px;}
.xmy_c_6_cocntrit_Click{width:140px;}
.xmy_c_6_cocntrit_cont{width:140px;height:40px;line-height:20px;overflow:hidden;}
.xmy_c_6_cocntrit_more{width:140px;}


.xmy_c_7_wrap{position: fixed;bottom: 200px;right:20px;display: none;}
.xmy_c_7_main{width: 80px;height: 60px;line-height: 60px;background: #ccc;text-align: center;cursor: pointer;}


.xmy_c_9_wrap{width:300px;margin:20px auto;}
.xmy_c_9_head{width:100%;height:50px;line-height:50px;}
.xmy_c_9_UpControl{background:url(https://1.rc.xiniu.com/rc/Contents/7691/Images/incsp1_up_but.png?d=20190115152656868) no-repeat center;width:100%;height:37px;cursor:pointer;}
.xmy_c_9_DownControl{background:url(https://1.rc.xiniu.com/rc/Contents/7691/Images/incsp1_dowmy_but.png?d=20190115152656868) no-repeat center;width:100%;height:37px;cursor:pointer;}
.xmy_c_9_wbox{width:100%;height:660px;overflow:hidden;}
.xmy_c_9_slide{width:100%;height:900000px;}
.xmy_c_9_list{width:100%;height:200px;margin-top:20px;}
.xmy_c_9_img{width:100%;height:100px;}
.xmy_c_9_img img{width:100%;height:100px;}
.xmy_c_9_content{width:100%;height:100px;}
.xmy_c_9_title{width:100%;height:22px;line-height:22px;overflow:hidden;text-align:center;}
.xmy_c_9_time{width:100%;height:22px;line-height:22px;overflow:hidden;text-align:center;}
.xmy_c_9_neirong{width:100%;height:44px;line-height:22px;overflow:hidden;text-align:center;}


.xmy_c_10_wrap{width:900px;margin:20px auto;}
.xmy_c_10_wbox{width:100%;}
.xmy_c_10_list{width:300px;float:left;}
.xmy_c_10_img{width:300px;height:200px;}
.xmy_c_10_img img{width:300px;height:200px;}
.xmy_c_10_neirong{height:44px;line-height:22px;overflow:hidden;}


	.xmy_c_11_wrap{width:300px;margin:20px auto;}
	.xmy_c_11_main{}
	.xmy_c_11_menu1{border: 1px solid #ccc;margin:30px;padding:10px;position: relative;}	
	.xmy_c_11_menu1_cur{border: 1px solid #ccc;margin:30px;padding:10px;position: relative;}	
	.xmy_c_11_menu1_cur ul{margin:10px 0;}
	.xmy_c_11_menu1_cur >a{background:red;font-weight: bold;color:#fff;display: inline-block;width: 80%;height: 30px;line-height: 30px;position: absolute;top:-15px;left: 50%;margin-left:-40%;text-align: center;}
	.xmy_c_11_menu1 ul{margin:10px 0;}	
	.xmy_c_11_menu1 >a{background:#ccc;font-weight: bold;color:#333;display: inline-block;width: 80%;height: 30px;line-height: 30px;position: absolute;top:-15px;left: 50%;margin-left:-40%;text-align: center;}
	.xmy_c_11_menu2 >a{color: #666;}
	.xmy_c_11_menu2 {margin:5px 0px;}
	.xmy_c_11_menu2_cur a{color: red;}


.xmy_c_12_wrap{width:300px;margin:20px auto; position:relative;}
.xmy_c_12_control{position:absolute;top:350px;right:20px;}
.xmy_c_12_control span{height:12px;width:12px;	background:#999;display:inline-block;border-radius:50%;cursor:pointer;margin:2px;}
.xmy_c_12_lights_active{background:rgb(113,113,11) !important;}
.xmy_c_12_content{width:300px;height:380px;overflow: hidden;}
.xmy_c_12_content ul{width:90000px;}
.xmy_c_12_box{width:300px;float:left;}
.xmy_c_12_title{width:300px;height:20px;}
.xmy_c_12_neirong{width:300px;height:66px;line-height:22px;overflow:hidden;}
.xmy_c_12_img{width:300px;height:225px;}
.xmy_c_12_img img{width:300px;height:225px;}
.xmy_c_12_More{width:300px;height:20px;}


.xmy_c_13_wrap{width:300px;margin:20px auto;}
.xmy_c_13_wbox{width:300px;}
.xmy_c_13_img{width:300px;height:300px;}
.xmy_c_13_img img{width:300px;height:300px;}
.xmy_c_13_neirong{height:44px;line-height:22px;overflow:hidden;}


.xmy_c_14_wrap{width:300px;margin:20px auto;}
.xmy_c_14_wbox{width:300px;}
.xmy_c_14_img{width:300px;height:300px;}
.xmy_c_14_img img{width:300px;height:300px;}
.xmy_c_14_neirong{height:44px;line-height:22px;overflow:hidden;}


.xmy_c_15_wrap{width:900px;margin:20px auto;}
.xmy_c_15_list{width:300px;float:left;}
.xmy_c_15_img{width:300px;height:200px;}
.xmy_c_15_img img{width:300px;height:200px;}
.xmy_c_15_neirong{height:66px;line-height:22px;overflow:hidden;}


.xmy_c_16_wrap{width:200px;margin:20px auto;}
.xmy_c_16_left_menu1{background:#eaeaea;}


.xmy_c_17_wrap{width:300px;margin:20px auto;height:330px;}
.xmy_c_17_wbox{width:300px;overflow:hidden;height:330px;}
.xmy_c_17_list{width:300px;float:left;height:310px;}
.xmy_c_17_control span{height:12px;width:12px;background:#999;display:inline-block;border-radius:50%;cursor:pointer;margin:5px;}
.xmy_c_17_lights_active{background:rgb(113,113,11) !important;}
.xmy_c_17_img{width:300px;height:200px;}
.xmy_c_17_img img{width:300px;height:200px;}
.xmy_c_17_neirong{height:44px;line-height:22px;overflow:hidden;}
.xmy_c_17_bottom{height:30px;}
.xmy_c_17_bottomMore{width:50%;float:left;text-align:center;line-height:30px;background:red;}
.xmy_c_17_bottomFeedback{width:50%;float:left;text-align:center;line-height:30px;background:green;}


.xmy_c_18_wrap{width:200px;margin:20px auto;}
.xmy_c_18_topbox{width:200px;line-height:30px;}
.xmy_c_18_nrwbox{width:200px;}
.xmy_c_18_xhbox1{width:200px;}
.xmy_c_18_xhbox1 .xmy_c_18_typeimg img{width:200px;height:150px;}
.xmy_c_18_typename{width:200px;height:30px;}
.xmy_c_18_xhbox2{width:200px;}
.xmy_c_18_xhbox2 .xmy_c_18_typeimg{display:none;}
.xmy_c_18_xhbox3 .xmy_c_18_typeimg{display:none;}
.xmy_c_18_xhbox4 .xmy_c_18_typeimg{display:none;}


.xmy_c_19_wrap{width:260px;margin:20px auto;}
.xmy_c_19_topbox{width:260px; line-height:30px;}
.xmy_c_19_main{position:relative;}
.xmy_c_19_left{height:75px;width:30px;background:#000;cursor:pointer;position:absolute;margin-top:100px;left:0px;z-index:999;}
.xmy_c_19_right{height:75px;width:30px;background:#000;cursor:pointer;position:absolute;margin-top:100px;right:0px;z-index:999;}
.xmy_c_19_inner{position:relative;float:left;width:200px;overflow:hidden;margin:0px 30px;}
.xmy_c_19_ul{position:relative;float:left;width:220px;}
.xmy_c_19_li{float:left;margin:0px;width:200px;padding:0px 10px;}
.xmy_c_19_nrbox{width:200px;}
.xmy_c_19_lftbox img{width:200px; height:150px;}
.xmy_c_19_coent{width:200px;height:44px;line-height:22px;overflow:hidden;}


.xmy_c_20_wrap{width:300px;height:300px;margin:20px auto;}
.xmy_c_20_list{width:300px;height:300px;}
.xmy_c_20_img{width:300px;height:300px;}
.xmy_c_20_img img{width:300px;height:300px;}
.xmy_c_20_title{display:none;}


.xmy_c_21_wra{width:500px;margin:20px auto;}


.xmy_c_23_wrap{width:250px;margin:20px auto;}
.xmy_c_23_topbox{width:250px;line-height:30px;}
.xmy_c_23_lftbox img{width:100px;height:100px;}
.xmy_c_23_coent{width:150px;height:22px;line-height:22px;overflow:hidden;}
.xmy_c_23_xhbox1{width:250px;}
.xmy_c_23_xhbox1 .xmy_c_23_lftbox{float:left;width:100px;}
.xmy_c_23_xhbox1 .xmy_c_23_ritbox{float:left;width:150px;}
.xmy_c_23_xhbox2{width:250px;}
.xmy_c_23_xhbox2 .xmy_c_23_lftbox{float:right;width:100px;}
.xmy_c_23_xhbox2 .xmy_c_23_ritbox{float:left;width:150px;}


.xmy_c_24_wrap{width:300px;margin:20px auto;height:330px;}
.xmy_c_24_wbox{width:300px;overflow:hidden;height:330px;}
.xmy_c_24_list{width:300px;float:left;height:310px;}
.xmy_c_24_control span{height:12px;width:12px;background:#999;display:inline-block;border-radius:50%;cursor:pointer;margin:5px;}
.xmy_c_24_lights_active{background:rgb(113,113,11) !important;}
.xmy_c_24_img{width:300px;height:200px;}
.xmy_c_24_img img{width:300px;height:200px;}
.xmy_c_24_neirong{height:44px;line-height:22px;overflow:hidden;}
.xmy_c_24_bottom{height:30px;}


.xmy_c_25_wrap{width:500px;margin:20px auto;}
.xmy_c_25_topbox{width:500px;line-height:30px;}
.xmy_c_25_xhbox{width:500px;}
.xmy_c_25_lftbox{float:left;width:200px;}
.xmy_c_25_lftbox img{width:200px;}
.xmy_c_25_ritbox{float:left;width:300px;}
.xmy_c_25_coent{width:300px;height:66px;line-height:22px;overflow:hidden;}


.xmy_c_26_wrap{width:500px;margin:20px auto;}
.xmy_c_26_topbox{width:500px;line-height:30px;}
.xmy_c_26_nrbox{width:500px;overflow:hidden;}
.xmy_c_26_main{width:500px;overflow:hidden;height:400px;}
.xmy_c_26_ul{overflow:hidden;}
.xmy_c_26_ul li{width:500px;}
.xmy_c_26_xhbox{width:500px;height:80px;padding:0px 0px 20px 0px;}
.xmy_c_26_img{display:none;}
.xmy_c_26_time{display:none;}
.xmy_c_26_title{width:500px;height:20px;}
.xmy_c_26_coent{width:500px;height:60px;line-height:20px;overflow:hidden;}


.xmy_c_2_wrap{clear:both;overflow:hidden;width:1000px;height:191px;position:relative;}
.xmy_c_2_center{width: 935px;overflow: hidden;margin:0 auto;}
.xmy_c_2_center ul{height:170px;}
.xmy_c_2_default_1{display:table;position:relative;float: left;width:190px;height:159px;text-align:center;*position:relative;*left:0;background:url(https://1.rc.xiniu.com/rc/Contents/7691/images/xmy_c_2_hover_1bg1.png?d=20190115152656868) no-repeat top center;}
.xmy_c_2_default_1:hover{background:url(https://1.rc.xiniu.com/rc/Contents/7691/images/xmy_c_2_hover_1bg.png?d=20190115152656868) no-repeat top center;}
.xmy_c_2_default_1 a{display:table-cell;width:94px;max-height:50px;text-align: center; overflow:hidden; vertical-align: middle;white-space:pre-wrap;position:absolute;top:58px;left:49px;line-height:25px;color:#ffffff;}
.xmy_c_2_left{height:38px;width:19px;cursor: pointer;background: url(https://1.rc.xiniu.com/rc/Contents/7691/images/xmy_c_2_leftbghover.png?d=20190115152656868) no-repeat;position:absolute;top:83px;left:0px;}
.xmy_c_2_left:hover{background: url(https://1.rc.xiniu.com/rc/Contents/7691/images/xmy_c_2_leftbg.png?d=20190115152656868) no-repeat;}
.xmy_c_2_right{height:38px;width:19px;cursor: pointer;background:url(https://1.rc.xiniu.com/rc/Contents/7691/images/xmy_c_2_rightbg.png?d=20190115152656868) no-repeat;position:absolute;top:83px;right:0px;}
.xmy_c_2_right:hover{background:url(https://1.rc.xiniu.com/rc/Contents/7691/images/xmy_c_2_rightbghover.png?d=20190115152656868) no-repeat;}
.xmy_c_2_inner{width:960px;height:159px;overflow: hidden;position:absolute;top:29px;left:20px;}
.xmy_c_2_focs_1{display:table;position:relative;float: left;width:190px;height:159px;text-align:center;*position:relative;*left:0;background:url(https://1.rc.xiniu.com/rc/Contents/7691/images/xmy_c_2_hover_1bg.png?d=20190115152656868) no-repeat top center;}
.xmy_c_2_focs_1 a{display:table-cell;width:94px;max-height:50px;text-align: center; overflow:hidden; vertical-align: middle;white-space:pre-wrap;position:absolute;top:58px;left:49px;line-height:25px;color:#ffffff;}


.lxwm_cont_head{ text-align:left; font-weight:bold; font-size:16px; color:#d60101; height:30px; border-bottom:1px dashed #ebebeb;}
.lxwm_cont{ width:100%; padding:0px 0 35px 0; font-weight:normal;     margin-top: 40px;}



.index_body .xmy_f_21_wrap{display:block;}
.xmy_f_21_wrap{width:1200px;height:auto;margin:0 auto;overflow:hidden;display:none;text-overflow:ellipsis;white-space:nowrap;font-size:16px ! important;position: relative;padding-top:20px;}
.xmy_f_21_wrap>span{display:block;float:left;margin-right:20px;font-size: 16px;color:#afafaf}
.xmy_f_21_wrap a{font-size: 14px ! important;}
.xmy_f_21_xhbox{float:left;height: 25px;overflow:hidden;text-align:center;margin-right:25px;}


.foot_tel{float:left;margin-top:20px;background:url(https://1.rc.xiniu.com/rc/Bottoms/50392/images/ea_b_xian.png?d=20190117092717733);background-repeat:no-repeat;background-position:right top;padding-right:54px;max-width:315px;}
.foot_zip{font-size:18px;color:#fff;margin-bottom: 10px;}
.foot_add{margin-top:20px;padding-left:25px;background:url(https://1.rc.xiniu.com/rc/Bottoms/50392/images/ea_b_wz.png?d=20190117092717733);background-repeat:no-repeat;background-position:left 5px;font-size:14px;color:#b0b0b0;}
.foot_phone{font-size: 28px;background:url(https://1.rc.xiniu.com/rc/Bottoms/50392/images/ea_b_dh.png?d=20190117092717733);background-repeat:no-repeat;background-position:left;height: auto;overflow: hidden;padding-left: 25px;line-height: 35px;}
.foot_tel_f{ position:relative; width:100%; height:100%;}
.foot_tel a{color:#999; }
.foot_fax{width:225px;height:42px;text-align:center;margin-top:15px;margin-bottom:30px;line-height:42px;}
.foot_fax a{font-size:14px;color:#fff;line-height:42px;display:block;width:100%;height:100%;background:#154a9a;opacity:0.9}
.foot_fax:hover a{opacity:1;color:#fff}


.xmy_c_98_wrap{overflow:hidden;float: left;padding-bottom: 6px;padding-right:39px;background:url(https://1.rc.xiniu.com/rc/Bottoms/50392/images/ea_b_xian.png?d=20190117092717733);background-repeat:no-repeat;background-position:right top;margin-top: 20px;}
.xmy_c_98_top{height:50px;line-height:50px;font-size:20px;color:#FFF;margin-bottom:10px;background-color:#035CAC;text-align:center;display: none;}
.xmy_c_98_main{width: 315px;height:auto;overflow:hidden;float: left;margin: 0 0 0 39px;}
.EIMS_C_40032_3_text,.EIMS_C_40032_3_select,.EIMS_C_40032_3_date{height: 33px;line-height:34px;overflow:hidden;margin-bottom:5px;position:relative;font-size:12px;color: #898989;width: 155px;float: left;margin-right: 5px;background: #565656;}
#EIMS_C_40032_3_Mobilebox{margin-right:0px}
.EIMS_C_40032_3_text label,.EIMS_C_40032_3_date label{height: 33px;z-index:23;}
.EIMS_C_40032_3_text input,.EIMS_C_40032_3_select select{width:100%;border:0;font-size: 12px;color: #898989;z-index:22;height: 33px;display: block;background: none;outline: 0;line-height:33px;text-indent: 10px;}
.EIMS_C_40032_3_date input{width:100%;height:34px;border:0;position:absolute;left:0;top:0;font-size:12px;color:#b5b5b5;text-indent:10px;z-index:22;line-height:33px;display:block;}
.EIMS_C_40032_3_select label{display:none;}
.EIMS_C_40032_3_textarea{width: 315px;height: 70px;overflow:hidden;color: #898989;position:relative;float: left;background: #565656;margin-bottom: 5px;}
.EIMS_C_40032_3_textarea label{
    
}
.EIMS_C_40032_3_textarea textarea{width: 100%;height: 70px;overflow:hidden;line-height:20px;font-size:12px;color: #999999;background: none;border:0;outline: 0;line-height:30px;text-indent: 10px;}
.xmy_c_98_Submit{width:100%;height: 30px;line-height: 30px;text-align:center;float: left;}
.xmy_c_98_Submit input{width: 100%;height: 30px;display:inline-block;font-size: 12px;color: #fff;text-align:center;cursor:pointer;float: left;opacity: 0.8;border: 0px;background: #154a9a;}
.xmy_c_98_Submit input:hover{opacity:1;}
.xmy_c_98_Reset{display:none;}
#EIMS_C_40032_3_Emailbox{margin-right:0px;}
.xmy_c_98_Name1,.xmy_c_98_Mobile1,.xmy_c_98_Email1,.xmy_c_98_Content1{height: 32px;width: 98%;font-size: 12px;color: #898989;position: absolute;left: 5px;top: 0px;}
.xmy_c_98_Email2,.xmy_c_98_Mobile2,.xmy_c_98_Name2{height: 33px;width:100%;display:block;font-size:14px;position: relative;}
.xmy_c_98_Content2{width:100%;display:block;height: 68px;}
.xmy_c_98_wrap .foot_zip{margin-left: 40px;margin-bottom: 17px;}


.foot_copy{width:100%;height:40px;text-align:left;line-height:40px;color:#666 ! important;}
.foot_copy a{color:#666 ! important;}
.foot_copy_name,.foot_copy_ba{display:inline-block;}


.xmy_f_2_warp img{position:absolute;bottom:13px;right:0px;}


.foot{  width:100%; position:relative; text-align:center;  height:auto;color:#fff; line-height:25px; }
.foot a{ font-size:14px; color:#afafaf; text-decoration:none; line-height:25px;}
.foot a:hover{	text-decoration: underline;	}
.foot_c{position:relative; width:100%; margin:auto; text-align:left;}


.foot_c_t1{width:100%;height:auto;overflow:hidden;background:#3a3a3a}
.foot_c_t1_box{width:1200px;margin:0 auto;height:auto;overflow:hidden;}
.foot_dh{float:left;background:url(https://1.rc.xiniu.com/rc/Bottoms/50392/images/ea_b_xian.png?d=20190117092717733);background-repeat:no-repeat;background-position:right top;margin-top:20px;padding-bottom: 14px;}
.foot_dh .foot_zip{padding-left:35px;margin-bottom: 20px;}
.foot_zip_r{width:auto;height:auto;overflow:hidden;max-width: 260px;}
.foot_zip_r a{display:block;float:left;line-height: 32px;width: 129px;text-align:center;}
.foot_ewm{float:left;margin-left:35px;width:136px;overflow:hidden;margin-top:20px;text-align:center;}
.foot_ewm img{width:136px;height:134px;}
.foot_ewm span{display:block;line-height:40px;font-size:14px;color:#b0b0b0;}


.foot_c_t2{width:100%;height:auto;overflow:hidden;background: #2d2d2d;}
.foot_c_t2_box{width:1200px;margin:0 auto;height:40px;line-height:40px;position:relative;}









 
 </style>