<template>

    <ul class="mainmenu">


        <li   v-for="(item,index) in menulist" class="has-droupdown has-menu-child-item">
            <a href="#">
                
                <router-link v-if="item.sfxw==='首页'" to="/">
                 {{item.strName}}
                </router-link>
                  <router-link v-else-if="item.sfxw==='新闻'" :to="{name:'empty',params: {zcdid:item.id,cruId:item.ejcd.length==0?item.id:item.ejcd[0].id,ejcd:item.ejcd,topath:'xwzx'}}">
                 {{item.strName}}
                </router-link>
                <router-link v-else-if="item.sfxw==='行业领先'" :to="{name:'empty',params: {zcdid:item.id,cruId:item.ejcd.length==0?item.id:item.ejcd[0].id,ejcd:item.ejcd,topath:'hylx'}}">
                 {{item.strName}}
                </router-link>
                <router-link v-else-if="item.sfxw==='加入我们'" :to="{name:'empty',params: {zcdid:item.id,cruId:item.ejcd.length==0?item.id:item.ejcd[0].id,ejcd:item.ejcd,topath:'zhaopin'}}">
                 {{item.strName}}
                </router-link>
                 <router-link v-else-if="item.sfxw==='来访预约'" :to="{name:'empty',params: {zcdid:item.id,cruId:item.ejcd.length==0?item.id:item.ejcd[0].id,ejcd:item.ejcd,topath:'lfyy'}}">
                 {{item.strName}}
                </router-link>
                 <router-link v-else-if="item.strName==='合作展望'" :to="{name:'empty',params: {zcdid:item.id,cruId:item.ejcd.length==0?item.id:item.ejcd[0].id,ejcd:item.ejcd,topath:'mypage2'}}">
                 {{item.strName}}
                </router-link>
                 <router-link v-else :to="{name:'empty',params: {zcdid:item.id,cruId:item.ejcd.length==0?item.id:item.ejcd[0].id,ejcd:item.ejcd,topath:'mypage'}}">
                 {{item.strName}}
                </router-link>
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </a>
           
             <ul  v-if="item.ejcd.length >0" class="submenu">
                <li v-for="(item1,index1) in item.ejcd" >
                  <router-link v-if="item.sfxw==='新闻'"  :to="{name:'empty',params: {zcdid:item.id,cruId:item1.id,ejcd:item.ejcd,topath:'xwzx'}}">
                {{item1.strName}}
                </router-link>
                <router-link v-else-if="item.sfxw==='加入我们'" :to="{name:'empty',params: {zcdid:item.id,cruId:item.ejcd.length==0?item.id:item.ejcd[0].id,ejcd:item.ejcd,topath:'zhaopin'}}">
                 {{item1.strName}}
                </router-link>
                 <router-link v-else-if="item.sfxw==='来访预约'" :to="{name:'empty',params: {zcdid:item.id,cruId:item.ejcd.length==0?item.id:item.ejcd[0].id,ejcd:item.ejcd,topath:'lfyy'}}">
                 {{item.strName}}
                </router-link>
                 <router-link v-else  :to="{name:'empty',params: {zcdid:item.id,cruId:item1.id,ejcd:item.ejcd,topath:'mypage'}}">
                {{item1.strName}}
                </router-link>
                </li>
                
                 
            </ul>
        </li>
      
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'Nav',
        components: {Icon},
         data() {
            return {
				menulist:[
					 
				],
		
		

               
            }
        },
        created(){
            debugger;
     this.querAll();
        },
          methods: {
          querAll() {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getBbjl', null).then(function (response) {
         
          self.menulist=response.data.menulist;

        })
    },
    }
    }
</script>
 <style scoped   >
 ul li a:hover {
   color: #ffffff;
    background: #1C8CFF;
}
 
</style>
