<template>
  <Layout :footer-style="2">
    <div v-html="showZcdNr">
    
 </div>
	<div id="ea_c">
            <div class="n_main">
                <div class="n_content">
                     <div class="n_content_left">
                    <div class="n_content_left_wrap">

                        <div id="n_content_left_cnt" class="n_content_left_cnt">
                            <ul id="ShoveWebControl_VMenu2" class="left_typeUl left_typeUl_n000" style="display: block;">
<ul id="left_typeUl_t200" class="left_typeUl left_typeUl_t200">

<li  v-for="item in ejcd"  id="left_typeLi_t200_1" data_id="2_5" :class="{'left_menu1_cur': cruId===item.id, 'left_menu1': cruId!=item.id}" type="t" index="1" level="0"  @click="getListData(item)" ><a  href="#" target="_self"><span name="ShoveWebControl_VMenu21654180832|T_NewsTypes|Id|5|Name">{{item.strName}}</span></a>
</li>

</ul>
</ul>
                       
                        </div>


                            <div id="n_content_right_name_r" class="n_content_right_name_r"> 
                                <ul>
                                    <li><span class="n_r_wz1">
                                        
                                        <span name="_n_r_wz1">您现在的位置：</span>
                                        
                                    </span><span class="n_r_wz2">
                                        
                                            <router-link   to="/">
               <span name="_n_r_wz2">首页</span>
                </router-link>
                                    </span><span class="n_r_wz3">→</span>
                                   <span class="n_r_wz4">
                                            <span id="ShoveWebControl_Text_news">
                                                 <router-link  :to="{name:'empty',params: {zcdid:zcdid,cruId:cruId,ejcd:ejcd,topath:'mypage'}}">
               <a href="#" class="curr1">隆富远大</a>
                </router-link>
                                               
                                              </span>
                                        </span>
                                        <span class="n_r_wz5">→</span>
                                        <span class="n_r_wz6">
                                           
                                              <router-link   :to="{name:'empty',params: {zcdid:zcdid,cruId:cruId,ejcd:ejcd,topath:'mypage'}}">
               <a href="#" class="curr1">{{curentName}}</a>
                </router-link>
                                        </span>
                                    </li>
                                    
                                </ul>
                                
                            </div>

    </div>
                    </div>
                  <div class="n_content_right"> 
                        <div class="n_content_right_name">
</div>
                        <div class="n_content_right_m">

    <div class="honor_con_box1">
        
            <div id="xn_c_about_1_warp" class="xn_c_about_1_warp">
                <span id="ShoveWebControl_Text2" v-html="showNr"></span>
                
            </div>
        
    </div>  


   <div class="about_con_right_box1" style="min-height: 40px;">
        
                <div class="xn_c_about_9_17_wrap" id="xn_c_about_9_17_wrap">
                    <div class="xn_c_about_9_17_tName">
                        
                            <span class="xn_c_about_9_17_tCName" name="_c_about_9_tCName">新闻动态</span>
                        
                    </div>
                    <div class="xn_c_about_9_17_main" id="xn_c_about_9_17_main">
                       
                        <div class="xn_c_about_9_17_inner" id="xn_c_about_9_17_inner">
                               <swiper class="xn_c_100_wrap" :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in xwdt" :key="index">
             <div class="xn_c_about_9_17_img">
                                                <a target="_blank" href="#" :title="item.FTITLE">
                                                  <router-link   :to="{name:'empty',params: {zcdid:'16584562929600100000',cruId:item.id,ejcd:xwlist,topath:'xwxq'}}">
                                                                	   <img  :alt="item.FTITLE" :title="item.FTITLE" :src="item.FTPDZ">
                                                             </router-link>
                                                    
                                                </a>
                                            </div>
                                            <div class="xn_c_about_9_17_cont" >
                                                <div class="xn_c_about_9_17_title">
                                                    <a target="_blank" href="#">
                                                       <router-link   :to="{name:'empty',params: {zcdid:'16584562929600100000',cruId:item.id,ejcd:xwlist,topath:'xwxq'}}">
                                                                	    {{item.FTITLE}}
                                                             </router-link>
                                                    </a>
                                                </div>
                                                <div class="xn_c_about_9_17_content">
                                                    <a target="_blank" href="#">
                                                                <router-link   :to="{name:'empty',params: {zcdid:'16584562929600100000',cruId:item.id,ejcd:xwlist,topath:'xwxq'}}">
                                                                	 <span id="xn_c_about_9_17_data_xn_c_about_9_17_contText_5">{{item.FDESC}}...</span>
                                                             </router-link>
                                                    </a>
                                                </div>
                                                 
                                            </div>
            </swiper-slide>
             <div class="swiper-pagination" slot="pagination"></div>
             
          </swiper>
                        </div>
                        <div class="xn_c_about_9_17_right" id="xn_c_about_9_17_right"></div>
                    </div>
                      
                </div>
        
        
            <div id="xn_c_1_newswrap" class="xn_c_1_newswrap">
                <ul class="xn_c_1_content">
                    
                            <li class="xn_c_1_items"  v-for="(item, index) in xwdt">
                                <div class="xn_c_1_title">
                                    <a :title="item.FTITLE" href="#" target="_blank">
                                       <router-link   :to="{name:'empty',params: {zcdid:'16584562929600100000',cruId:item.id,ejcd:xwlist,topath:'xwxq'}}">
                                                                	    {{item.FTITLE}}
                                                             </router-link></a>
                                </div>
                                <div class="xn_c_1_ptime">{{item.sysLastTime}}</div>
                            </li>
                        
                            
                        
                </ul>
                                             
            </div>
        
        
            <div id="xn_c_about_video_wrap" class="xn_c_about_video_wrap">
                <div class="xn_c_about_video_tName">
                    
                        <span name="_xn_c_about_video_tCName">企业视频</span>
                    
                </div>
                <div class="xn_c_about_video_box">
                    <a href="javascript:;" class="xn_c_about_video_left">&lt;</a>
                    <ul class="xn_c_about_video_innerBox">
                        
                    </ul>
                    <a href="javascript:;" class="xn_c_about_video_right">&gt;</a>
                <div class="dotBox"></div></div>
                
            </div>
        
    </div>

</div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
 
	   
  </Layout>
</template>
   


 
 <script  src="./渝隆远大 - 装配式 - 建筑 - 重庆渝隆远大住宅工业有限公司_files/CgAGe1x4nuSAChb-AAAxAQ5EWVM6876.js.下载"   ></script>
<script>

 import { swiper, swiperSlide } from "vue-awesome-swiper";
  import "swiper/dist/css/swiper.css";
    import Layout from '../components/common/Layout'
 import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'MyPage',
        
         components: {  Layout,swiper,swiperSlide},
        data() {
            return {
              swiperOption: {
         autoplay: true,
         speed: 300,
         loop: true,
         pagination: {
          el: ".swiper-pagination", //分页器
          clickable: true,
         },
	  },
                xwlist:[],
                xwdt:[],
                showNr:"",
                showZcdNr:"",
                 cruId:this.$route.params.cruId,
                 ejcd:this.$route.params.ejcd,
                 cruName:"",
                 zcdid:this.$route.params.zcdid,

    
            }
        },
        // 页面销毁之前
    beforeDestroy() {
        sessionStorage.removeItem(this.$route.name+'paramsData')
   	},
        created(){
             debugger;
             var params = [];
          if(this.$route.params.zcdid){//从菜单点进来-有参数，直接取菜单参数,并将参数放入缓存
               
                 params = this.$route.params;
	            sessionStorage.setItem(this.$route.name+"paramsData", JSON.stringify(params));
            }else{//从浏览器返回或者刷新-无参数,取session中的参数,并且每次session中的参数都要刷新
                  params = JSON.parse(sessionStorage.getItem(this.$route.name+"paramsData"));
            }
             this.zcdid = params.zcdid;
                 this.cruId = params.cruId;
                  this.ejcd = params.ejcd;
            if(this.ejcd.length==0){//
                this.querAll(this.cruId,'DB_DEMO_MENU');
            }else{
                 
                this.cruName = this.cruId;
                
                this.querAllZcd(this.zcdid,'DB_DEMO_MENU');
                this.querAll(this.cruId,'DB_DEMO_MENU_DETAIL');
            }
            //this.querXw();//查询新闻
            this.querXwtd();//查询新闻
        },
        	methods:{
				querAllZcd(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showZcdNr=response.data.showNr;

        })
    },
    querXwtd() {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/xw/card/custom?service=getxwdt', null).then(function (response) {
         
          self.xwdt=response.data.xwlist[0].xwmx;
          self.xwlist=response.data.xwlist;

        })
    },
            querAll(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showNr=response.data.showNr;

        })
    },
    //  querXw() {
     
    //      var self = this;
    //     let headers = {
    //       'Content-Type': 'multipart/form-data'
    //     }     
       
    //       jjService.queryTest(jjService.apisrc+'/plugin/demo/xw/card/custom?service=getxwzxXw&&ftypeid=16584562929900100000&&pageNum=1&pageSize=10', null).then(function (response) {
         
    //       self.xwlist=response.data.xwRet.xwmx;
          
    //       self.$refs.mySwiper.reset();
    //     })
    // },
    getListData(item){
        this.cruId = item.id;
         this.curentName=item.strName;
        
        this.querAll(this.cruId,"DB_DEMO_MENU_DETAIL");
    },
		},
		 
    }
</script>
   <style scoped less src="./渝隆远大 - 装配式 - 建筑 - 重庆渝隆远大住宅工业有限公司_files/CgAGS10CCU2AGtVtAAA9bditA7o155.css">
   .swiper-pagination {
   bottom: 10px;
    left: 100px;
    width: 100%;
 }
 </style>