<template>
  
</template>
   


 
 
<script>

    export default {
        name: 'empty',
        data() {
            return {
                 params:[],
                 cruId:this.$route.params.cruId,
                 ejcd:this.$route.params.ejcd,
                 zcdid:this.$route.params.zcdid,
                topath:this.$route.params.topath
    
            }
        },
        // 页面销毁之前
    // beforeDestroy() {
    //     sessionStorage.removeItem('paramsData')
   	// },
        created(){
            debugger;
             
            if(this.$route.params.topath){//从菜单点进来-有参数，直接取菜单参数,并将参数放入缓存
                this.$router.push({
          name: this.topath,
          params: {
            cruId: this.cruId,
             zcdid: this.zcdid,
             ejcd: this.ejcd
          }
        })
            }else{//从浏览器返回或者刷新-无参数,取session中的参数,并且每次session中的参数都要刷新
               this.$router.push({
          name: "Demo"
        })
            }
            
 
        

      
        },
        	 
		 
    }
</script>
 <style scoped lang='less'>
 
 </style>