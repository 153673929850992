 
//import httpRequest from '@/axios'
import httpRequest from "axios";

export const jjService = {

  // http://192.168.10.108:8082
  reportData: (url, data, headers) =>
    httpRequest({
      url,
      // url: '/default/com.jinke.audit.report.reportComponent.addReportbizInterface.biz.ext',
      method: 'post',
      data,
      headers
    }),
    reportbasedata: (url, data,headers) =>
    httpRequest.post(url,data,headers),
    queryTest: (url, data) =>
    httpRequest({ url: url, method: 'get', data }),
    saveData: (url, data) =>
    httpRequest({
      url,
      // url: '/default/com.jinke.audit.report.reportComponent.addReportbizInterface.biz.ext',
      method: 'post',
      data
    }),
   
  //apisrc:'/api/xmw'//api定义那里不能加小魔王,必须在这里加
    //apisrc:'/api'//api定义那里不能加小魔王,必须在这里加
apisrc:'http://www.cqlfyd.com/xmw'

}