<template>
  <Layout :footer-style="2">
    <div v-html="showZcdNr">
    
 </div>
	<!--二级菜单区域 begin-->
 <div  >
        <el-tabs  v-model="cruName" v-if="ejcd.length >0" @tab-click="getListData">
         <el-tab-pane
           v-for="item in ejcd"
          :key="item.id"
          :value="item.id"
          :label="item.strName"
         :name="item.id"
       />
     </el-tabs>
 			
        </div>
 <div v-html="showNr">
    
 </div>
 
	   
  </Layout>
</template>
   


 
 
<script>
    import Layout from '../components/common/Layout'
 import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'MyPage',
        components: {  Layout},
        data() {
            return {
                showNr:"",
                showZcdNr:"",
                 cruId:this.$route.params.cruId,
                 ejcd:this.$route.params.ejcd,
                 cruName:"",
                 zcdid:this.$route.params.zcdid,

    
            }
        },
         // 页面销毁之前
    beforeDestroy() {
        sessionStorage.removeItem(this.$route.name+'paramsData')
   	},
        created(){
          
             var params = [];
          if(this.$route.params.zcdid){//从菜单点进来-有参数，直接取菜单参数,并将参数放入缓存
               
                 params = this.$route.params;
	            sessionStorage.setItem(this.$route.name+"paramsData", JSON.stringify(params));
            }else{//从浏览器返回或者刷新-无参数,取session中的参数,并且每次session中的参数都要刷新
                  params = JSON.parse(sessionStorage.getItem(this.$route.name+"paramsData"));
            }
             this.zcdid = params.zcdid;
                 this.cruId = params.cruId;
                  this.ejcd = params.ejcd;
             
            if(this.ejcd.length==0){//
                this.querAll(this.cruId,'DB_DEMO_MENU');
            }else{
                 
                this.cruName = this.cruId;
                
                this.querAllZcd(this.zcdid,'DB_DEMO_MENU');
                this.querAll(this.cruId,'DB_DEMO_MENU_DETAIL');
            }
        },
        	methods:{
				querAllZcd(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showZcdNr=response.data.showNr;

        })
    },
            querAll(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showNr=response.data.showNr;

        })
    },
    getListData(tab){
       
        this.querAll(tab.name,"DB_DEMO_MENU_DETAIL");
    },
		},
		 
    }
</script>
 <style scoped lang='less'>
 
 </style>