<template>
  <Layout :footer-style="2">
    <div v-html="showZcdNr">
    
 </div>
	  <div id="ea_c">
            <div class="n_main">
                <div class="n_content">

                            <div id="n_content_right_name_l" class="n_content_right_name_l" data-type="block">
                                <span class="n_content_right_name_l1">
                                    
                                    <span name="16">Recruitment</span>
                                    
                                </span>
                                <span class="n_content_right_name_l2">
                                    <span id="ShoveWebControl_Text1">招聘职位</span>
                                </span>
                                
                            </div>

                    <div class="n_content_left">

                        <div id="n_content_left_t" class="n_content_left_t" data-type="block">
                            <span class="n_content_left_t1">
                                <span id="ShoveWebControl_Text21">加入我们</span>
                            </span><span class="n_content_left_t2">
                                
                                <span name="_nleft_n_name1">Recruitment</span>
                                
                            </span>
							
                        </div>


                        <div id="n_content_left_cnt" class="n_content_left_cnt" data-type="block">
                            <ul id="ShoveWebControl_VMenu2" class="left_typeUl left_typeUl_n000" style="display: block;">
<ul id="left_typeUl_t600" class="left_typeUl left_typeUl_t600">
<li id="left_typeLi_t600_1" data_id="6_7" class="left_menu1" type="t" index="1" level="0"><a href="http://localhost:8080/zhaopin" rel="nofollow" target="_self"><span name="ShoveWebControl_VMenu2705370901|T_NewsTypes|Id|7|Name">招聘职位</span></a>
</li>
</ul>
</ul>
                            
                        </div>

                    </div>
                    <div class="n_content_right">
                        <div class="n_content_right_name">


        <div id="bk1_n_content_right_name_r" class="bk1_n_content_right_name_r" data-type="block" data-from="favorite" data-id="34130" data-index="1">
            <ul>
                <li>
                    <span class="bk1_n_r_wz1">
                        
                        <span name="bk1__n_r_wz1">您现在的位置：</span>
                        
                    </span>
                    <span class="bk1_n_r_wz2">
                        <a href="#">
                        <span name="bk1__n_r_wz2"> <router-link   to="/">
                                                                	    首页
                                                             </router-link></span>
                        </a>
                    </span>
                    <span class="bk1_n_r_wz3">→</span>
                    <span class="bk1_n_r_wz4">
                        <span id="bk1_ShoveWebControl_Text_about"><a href="#" target="_self"> <router-link   to="zhaopin">
                                                                	    加入我们
                                                             </router-link></a></span>
                    </span>
                    <span class="bk1_n_r_wz5">→</span>
                    <span class="bk1_n_r_wz6">
                        
                    </span>
                </li>
                 
            </ul>
            
        </div>


                        </div>
                        <div class="n_content_right_m">

                        	<div class="xn_c_recru_49_wrap" id="xn_c_recru_49_wrap" data-type="block">  
                        		
                                <div v-html="showNr" class="xn_c_recru_49_main">                       		
                                123
                                   
                                </div>
                                
                                
                        	</div>


                            

                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
 
	   
  </Layout>
</template>
   


 
 
<script>
    import Layout from '../components/common/Layout'
   
    
 import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'MyPage',
        components: {  Layout},
        data() {
            return {
                showNr:"",
                zpzw:[],
                total: 0,//总数
        pageNum: 1,//当前页
        pageSize: 10,//每页显示数量
                showZcdNr:"",
                 zwid:this.$route.params.zwid,
                 

    
            }
        },
        created(){
              
           this.querAllZcd('16590186500130100000','DB_DEMO_MENU');
            this.querAll(this.zwid,'DB_DEMO_ZPZW');// 分页查询职位详情
        },
        	methods:{
            
				querAllZcd(fid,tableName) {
      
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showZcdNr=response.data.showNr;

        })
    },
           querAll(fid,tableName) {
      
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showNr=response.data.showNr;

        })
    },
    getListData(tab){
       
        this.querAll(tab.name,"DB_DEMO_MENU_DETAIL");
    },
		},
		 
    }
</script>
 <style scoped   src="./加入我们 - 建筑 - 建设 - 重庆渝隆远大住宅工业有限公司_files/CgAGTF1s3RWAMgNnAAA1V_ZsM2U309.css">
 

 
 </style>