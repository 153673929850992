<template>
<Layout :footer-style="2">
   <div v-html="showZcdNr">
    
 </div>
 <div class="xn_c_products_126_wrap" id="xn_c_products_126_wrap" data-type="block">
                            
                            <div class="xn_c_products_126_title">
                                <div class="xn_c_products_126_title_left">
                                    <span class="xn_c_products_126_edi1"><span name="_edi_c51_1">项目展示</span> </span>
                                    <span class="xn_c_products_126_edi2"><span name="_edi_c51_2">Projects  show</span></span> 
                                </div>
                                <div class="xn_c_products_126_title_right">
                                    <router-link   to="xmzszx">
                                                                	     <a class="xn_c_products_126_edi3" href="#"><span name="_edi_c51_3">MORE</span></a> 
                                                             </router-link>
                                   
                                </div>
                            </div>
                            <div class="xn_c_products_126_con">
                              
                                <span class="xn_c_products_126_left"  @click="navPrev"></span>
                                <ul style="margin-left: 0px;" ref="dataNavList">
                                 <li v-for="(item, index) in xmlist" :style="{transform:'translateX(-'+move+'px)'}">
                                                <div class="xn_c_products_126_img">
                                                    <a target="_blank" >
                                                    
                                                     <router-link   :to="{name:'xmzsxq',params: {zcdid:item.id,cruId:item.id,ejcd:[],topath:'xmzsxq'}}">
                                                                	 <img :alt="item.FNAME" :title="item.FNAME" :src="item.FYL">
                                                             </router-link>
                                                    </a>
                                                </div>
                                                <div class="xn_c_products_126_content">
                                                    <a target="_blank" >
                                                   
                                                     <router-link   :to="{name:'xmzsxq',params: {zcdid:item.id,cruId:item.id,ejcd:[],topath:'xmzsxq'}}">
                                                                	 <span class="xn_c_products_126_author1">
                                                       {{item.FNAME}}
                                                    </span>
                                                             </router-link>
                                                    </a>
                                                    <span class="xn_c_products_126_author2">
                                                        <span id="xn_c_products_126_con_xn_c_products_126_Text4_2">项目地址：{{item.FADDS}}</span>
                                                    </span>
                                                </div>
                                            </li> </ul>
                                <span class="xn_c_products_126_right"  @click="navNext"></span>
                              
                            </div>
                            
                        </div>
                      <!-- 产品体系 begin-->   
<div id="xn_c_products_7_wrap" class="xn_c_products_7_wrap" data-type="block">
                            <div class="xn_c_products_7_title">
                                <div class="xn_c_products_7_title_left">
                                    <span class="xn_c_products_7_edi1"><span name="_edi_c7_1">产品体系</span> </span>
                                    <span class="xn_c_products_7_edi2"><span name="_edi_c7_2">Product  system</span></span> 
                                </div>
                                <div class="xn_c_products_7_title_right">
                                    <router-link  :to="{name:'empty',params: {zcdid:zcdid,topath:'hylx'}}">
                                                                	       <a class="xn_c_products_7_edi3" href="#"><span name="_edi_c7_3">MORE</span></a> 
                                                             </router-link>
                                  
                                </div>
                            </div>
                            <div class="xn_c_products_7_txt">
                                    <div v-for="(item, index) in cptxlist"  class="xn_c_products_7_cswbox1">
                                        <div class="xn_c_products_7_csimg">
                                              <a target="_blank"  >
                                                  <img   :alt="item.FNAME" :title="item.FNAME" :src="item.FTPDZ">
                                              </a>
                                        </div>
                                        <div class="xn_c_products_7_cscont">
                                            <div class="xn_c_products_7_cstime"> 
                                                <span class="xn_c_products_7_casetime3">
                                                    <span id="xn_c_products_7_Data_xn_c_products_7_Text2_0">{{item.FNUMBER}}</span>
                                                </span> 
                                                 
                                            </div>
                                            <div class="xn_c_products_7_cstitle">
                                                    
                                                     <router-link   :to="{name:'cpxq',params: {cruId:item.id}}">
                                                                	   {{item.FNAME}}
                                                             </router-link>
                                                  
                                                    
                                            </div>
                                            <div class="xn_c_products_7_csneirong">
                                                <span id="xn_c_products_7_Data_xn_c_products_7_Text4_0"></span>
                                            </div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>  
                                    
                                     
                                    
                                
                            </div>
                              
                        </div>





                       
                        <!--产品体系 end-->
                        <!--下面的 begin-->
                        <br>
                        <br>
                        <div v-html="hylxNr">
                           
                            
                            
                        </div>
</Layout>
</template>
   

 
<script>
 import Layout from '../components/common/Layout'
 
import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'hylx',
         components: {  Layout},
         data () {
    return {
        xmlist:[],
        cptxlist:[],
        move:0,
        dataNavListRealWidth:0,
        dataNavListViewWidth:0,
        showZcdNr:"",
        hylxNr:"",
        zcdid:this.$route.params.zcdid,
    };
},
 // 页面销毁之前
    beforeDestroy() {
        sessionStorage.removeItem(this.$route.name+'paramsData')
   	},
mounted() {
    debugger;
      var params = [];
          if(this.$route.params.zcdid){//从菜单点进来-有参数，直接取菜单参数,并将参数放入缓存
               
                 params = this.$route.params;
	            sessionStorage.setItem(this.$route.name+"paramsData", JSON.stringify(params));
            }else{//从浏览器返回或者刷新-无参数,取session中的参数,并且每次session中的参数都要刷新
                  params = JSON.parse(sessionStorage.getItem(this.$route.name+"paramsData"));
            }
             this.zcdid = params.zcdid;
               
  this.querAllZcd(this.zcdid,'DB_DEMO_MENU');//查询主菜单内容--顶部图片
  this.querHylx('hylx','DB_DEMO_MENU_DETAIL');
  this.querXmzs();//查询项目展示
	this.querCptx();//查询产品体系
    //实际宽度减去可视宽度就是可移动的范围即move的范围
    // 窗口大小变化时触发。实时更新可视宽度
    window.onresize = () => {
        return (() => {
            this.dataNavListViewWidth = this.$refs.dataNavList.offsetWidth;
            //注1，详见下方
            if(this.move>this.dataNavListRealWidth-this.dataNavListViewWidth-410){
                this.move=this.dataNavListRealWidth-this.dataNavListViewWidth;
            }
        })()}
},
methods:{
  querAllZcd(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showZcdNr=response.data.showNr;

        })
    },
    querHylx(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.hylxNr=response.data.showNr;

        })
    },
	// 当菜单项向右的可移动距离大于单个菜单项的宽度时，move减去一个菜单项的宽度（即右移移动一个菜单项的宽度），
	// 当菜单项向右的可移动距离小于单个菜单项的宽度时，move等于0（即回到最开始没有移动的状态）
    navPrev(){
       
        if(this.move>410){
            this.move=this.move-410;
        }else{
            this.move=0
        }
    },
    // 当菜单项向左的可移动距离大于单个菜单项的宽度时，move减去一个菜单项的宽度（即左移移动一个菜单项的宽度），
	// 当菜单项向左的可移动距离小于单个菜单项的宽度时，move等于可以向左移动的最大值（即到最末尾）
    navNext(){
       
        if(this.move<this.dataNavListRealWidth-this.dataNavListViewWidth-410){
            this.move=this.move+410;
        }
        else{
            this.move=this.dataNavListRealWidth-this.dataNavListViewWidth
        }
    },
    querXmzs(){
					debugger;
					var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/xmzs/card/custom?service=getXmlist&&pageNum=1&pageSize=10', null).then(function (response) {
         
      self.xmlist=response.data.xMzxRet.xwmx;
      // scrollWidth获取整个菜单实际宽度
    self.dataNavListRealWidth = self.$refs.dataNavList.scrollWidth;
     
    	 self.xmlist.push();
		  self.$refs.dataNavList.reset();
    // offsetWidth获取菜单在当前页面可视宽度
    self.dataNavListViewWidth = self.$refs.dataNavList.offsetWidth;
      
        })
        },
        querCptx(){
					debugger;
					var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/cptx/card/custom?service=getCptxlist', null).then(function (response) {
         
      self.cptxlist=response.data.cptxlist;
      
      
        })
				},
},
		 
    }
</script>
 <style lang="scss" scoped>

.xn_c_products_126_con{display: flex;width:100%;height:auto;overflow:hidden;position:relative;margin-top: 26px;}
.xn_c_products_126_con ul{display: flex;width:10000px;height:auto;overflow:hidden;position:relative;z-index:1;}
.xn_c_products_126_con ul li{display: inline-block;width:380px;height:400px;float:left;margin-right:30px;background: #f7f7f7;}
.xn_c_products_126_con ul li .xn_c_products_126_img{width:380px;height:253px;overflow: hidden;}
.xn_c_products_126_con ul li  img{width:100%;height:100%;display:block;position:relative;z-index:1;transition: all 1.2s;
    -moz-transition: all 1.2s;
    -webkit-transition: all 1.2s;
    -o-transition: all 1.2s;}
.xn_c_products_126_con ul li  img:hover{ transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);}
 
</style>