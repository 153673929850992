<template>
  <Layout :footer-style="2">
	<!--轮播图片区域 begin-->
<div>
          <swiper class="banner" :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in imgArr" :key="index">
              <img :src="item" alt=""/>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
<!--轮播图片区域 end-->
<!--公司简介 begin-->
  <div id="index_cnt_m_about" class="index_cnt_m_about" data-type="block">
                    
                    <div class="index_about_f">
                        <div class="index_cnt_m_about_t">
                            <span class="i_about_t1">
                                
                                <span name="701"></span>
                                
                            </span>
							<span class="i_about_t2">
                                
                                <span name="702">公司简介</span>
                                
                            </span>
							<span class="i_about_t3">
                                
                                <span name="703">ABOUT US</span>
                                
                            </span>
                        </div>
						<div class="about_f_box">
							<div class="about_f_1">
								<div class="about_f_1img">
								
    <vueMiniPlayer ref="vueMiniPlayer" :video="video" :mutex="true"   />	 
								
								</div>
							</div>
							<div class="about_f_2">
							</div>
							<div class="index_cnt_m_about_cnt">
							<div class="about_cnt_t">
								<span class="i_about_t11">
                                
                                <span name="7021">企业简介</span>
                                
								</span>
								<span class="i_about_t21">
									
									<span name="7031">COMPANY PROFILE</span>
									
								</span>
							</div>
							<div class="about_cnt_con">
								<span class="i_about_cnt">
									<span id="ShoveWebControl_Text1" v-html="jjstr"></span>
								</span>
							</div>
							 <div class="index_cnt_m_about_more"> <router-link  :to="{name:'mypage',params: {zcdid:menulist[1].id,cruId:menulist[1].ejcd[0].id,ejcd:menulist[1].ejcd,topath:'mypage'}}">
                查看更多
                </router-link>
								 <!-- <a href="http://www.cqylyd.com/about.aspx?FId=n1:1:1">查看更多</a> -->
								 </div>
                        </div>
						</div>
                    </div>
                    
                    
                </div>
<!--公司简介 end-->
<!--新闻展示 begin-->
<div class="xn_c_index_138_wrap" id="xn_c_index_138_wrap" data-type="block">
					<div class="index_cnt_m_about_t">
						<span class="i_about_t1">
							
							<span name="7011"></span>
							
						</span>
						<span class="i_about_t2">
							
							<span name="7021">新闻中心</span>
							
						</span>
						<span class="i_about_t3">
							
							<span name="7031">NEW CENTER</span>
							
						</span>
					</div>
					
					<div class="xn_c_index_138_wbox"  id="hello">
						
								<div class="xn_c_index_138_typeList" v-for="(item,index) in xwlist">
									<div  :id="item.id" v-on:mouseover="handleOver(item.id)" >
										{{item.strName}}
									</div>
									<div  :id="item.id+'contType'">
										<ul class="xn_c_index_138_headUl">                                            
													<li v-for="(item1,index1) in item.xwmx" :class="'xn_c_index_138_headLi xn_c_index_138_list_'+(index1+1)">
														<!--左侧新闻-->
														<div v-if="index1 === 0">     
														<div class="xn_c_index_138_headTitle">
															<a :title="item1.title"  target="_blank">
																<router-link   :to="{name:'xwxq',params: {zcdid:'16584562929600100000',cruId:item1.id,ejcd:xwlist,topath:'xwxq'}}">
																		{{item1.FTITLE}}
																	</router-link>
															</a>
														</div>
														<div class="xn_c_index_138_headDate">
															 
															 
															<span class="xn_c_index_138_hMonth">
																<span ><router-link   :to="{name:'xwxq',params: {zcdid:'16584562929600100000',cruId:item1.id,ejcd:xwlist,topath:'xwxq'}}">
																		{{item1.FDATE}}
																	</router-link></span>
															</span>
															
														</div>
														 <div class="xn_c_index_138_hcontext">
															<span  >
																<router-link   :to="{name:'xwxq',params: {zcdid:'16584562929600100000',cruId:item1.id,ejcd:xwlist,topath:'xwxq'}}">
																		{{item1.FDESC}}
																	</router-link></span>
														</div>
														<div class="xn_c_index_138_headImg">
															<a :title="item1.FTITLE"   target="_blank">
																<router-link   :to="{name:'xwxq',params: {zcdid:'16584562929600100000',cruId:item1.id,ejcd:xwlist,topath:'xwxq'}}">
																		<img  :alt="item1.FTITLE" :title="item1.FTITLE" :src="item1.FTPDZ">
																	</router-link>
															
															</a>
														</div>
														<div class="xn_c_index_138_headMore">
															<a :title="item1.title" :href="item1.src"  target="_blank">
																
																<span name="_xn_c_index_138_headMore">
																	 <router-link   :to="{name:'xwzx',params: {zcdid:'16584562929600100000',cruId:item.id,ejcd:xwlist,topath:'xwzx'}}">
																			更多详细
																			</router-link>
																	
																	</span>
																
															</a>
														</div>
														</div>
														<!--右侧新闻-->
														<div v-if="index1 > 0">
														<div class="xn_c_index_138_headTitle">
															<a :title="item1.title" :href="item1.src" target="_blank">
															
																 <router-link   :to="{name:'xwxq',params: {zcdid:'16584562929600100000',cruId:item1.id,ejcd:xwlist,topath:'xwxq'}}">
																		{{item1.FTITLE}}
																	</router-link>
															</a>
														</div>
														 
														<div class="xn_c_index_138_hcontext">
															<span ><span  >
																<router-link   :to="{name:'xwxq',params: {zcdid:'16584562929600100000',cruId:item1.id,ejcd:xwlist,topath:'xwxq'}}">
																		{{item1.FDESC}}
																	</router-link>
																
																</span></span>
														</div>
														<div class="xn_c_index_138_headMore">
															<router-link   :to="{name:'xwxq',params: {zcdid:'16584562929600100000',cruId:item1.id,ejcd:xwlist,topath:'xwxq'}}">
																		<a :title="item1.FTITLE"   target="_blank">

															</a>
																	</router-link>
															
														</div>
															</div>
													</li> 
												     
												 
											          
										</ul>
										                                             
									</div>
								</div>
							
								 
							
					</div>
					<br>
				<br>
				<br>
				</div>
				
      <!--项目展示begin-->
	     
                            
                          <div class="index_cnt_m_about_t">
                            <span class="i_about_t1">
                                
                                <span name="7011"></span>
                                
                            </span>
							<span class="i_about_t2">
                                
                                <span name="7021">项目展示</span>
                                
                            </span>
							<span class="i_about_t3">
                                
                                <span name="7031">PROJECT DISPLAY</span>
                                
                            </span>
                        </div>
                          <div class="index-roll">
			
	<vue-seamless-scroll  :data="xmlist"
      class="seamless-warp"
      :class-option="classOption2"
	  ref="seamlessScroll"
    >
	 
      <ul class="scrool-item scrool-item2" style="">
        <li class="item" v-for="(item, index) in xmlist" :key="index">
			<div class="xn_c_index_42_my">
												<!-- <a target="_blank" href="http://www.cqylyd.com/prod_view.aspx?TypeId=66&amp;Id=168&amp;FId=t3:66:3">
												{{item.FNAME}}
											   </a>  -->
											   <router-link   :to="{name:'xmzsxq',params: {zcdid:item.id,cruId:item.id,ejcd:[],topath:'xmzsxq'}}">
                                                                	 <span class="xn_c_products_126_author1">
                                                       {{item.FNAME}}
                                                    </span>
                                                             </router-link>
											</div> 

 <router-link   :to="{name:'xmzsxq',params: {zcdid:item.id,cruId:item.id,ejcd:[],topath:'xmzsxq'}}">
                                                                	<img :src="item.FYL">
                                                             </router-link>

        </li>
      </ul>
    </vue-seamless-scroll>
		</div>
                            
                         
	  <!--项目展示end-->
	  <!--产品体系 begin-->
	  <div id="xn_c_index_42_wrap" class="xn_c_index_42_wrap" data-type="block">
                    
                    <div class="xn_c_index_42_wbox">
						<div class="index_cnt_m_about_t">
							<span class="i_about_t1">
								
								<span name="70111"></span>
								
							</span>
							<span class="i_about_t2">
								
								<span name="70211">产品体系</span>
								
							</span>
							<span class="i_about_t3">
								
								<span name="70311">PRODUCT SYSTEM</span>
								
							</span>
						</div>
                        <div class="xn_c_index_42_nrbox"  >
                             
								<div class="xn_c_index_42_nrsmall" v-for="(item, index) in cplist" :key="index">
									<div class="xn_c_index_42_lftbox">
										 <router-link   :to="{name:'cpxq',params: {cruId:item.id}}">
													 <a target="_blank" >
														 <img   :alt="item.FNAME" :title="item.FNAME" :src="item.FTPDZ">
											   		</a> 
												</router-link>
										
										 
									</div>
									<div class="xn_c_index_42_ritbox">
											<div class="xn_c_index_42_time">
													 <span class="xn_c_index_42_date1">
													<span id="xn_c_index_42_Data_xn_c_index_42_Text1_0">2019</span>
													</span>
													 <span class="xn_c_index_42_date2">-</span>
													<span class="xn_c_index_42_date3">
													<span id="xn_c_index_42_Data_xn_c_index_42_Text2_0">01</span>
													</span>
													<span class="xn_c_index_42_date4">-</span>
													<span class="xn_c_index_42_date5">
													<span id="xn_c_index_42_Data_xn_c_index_42_Text3_0">01</span>
													</span> 
											</div>
											<div class="xn_c_index_42_name">
												
											     <router-link   :to="{name:'cpxq',params: {cruId:item.id}}">
													 <a target="_blank" >
														 {{item.FNAME}}
											   		</a> 
												</router-link>
											</div> 
											<div class="xn_c_index_42_Unit">
												
											</div>
											<div class="xn_c_index_42_coent" ej="edit_span" style=""></div>
											<!-- <div class="xn_c_index_42_xxmore">
												 <a target="_blank" href="http://www.cqylyd.com/prod_view.aspx?TypeId=66&amp;Id=168&amp;FId=t3:66:3">查看更多2&gt;&gt;
												</a>
											</div> -->
									</div>
								</div>
                            <div class="clear"></div>   
                        </div>   
						<div class="xn_c_index_42_prdmore">
							<!-- <a href="http://www.cqylyd.com/pro.aspx?FId=n3:3:3">
								
								<span name="_index_42_prdmore">查看更多</span>
								
							</a> -->
							<router-link  :to="{name:'hylx',params: {zcdid:menulist[3].id,cruId:menulist[3].ejcd.length==0?menulist[3].id:menulist[3].ejcd[0].id,ejcd:menulist[3].ejcd,topath:'hylx'}}">
                 查看更多
                </router-link>
						</div> 
                    </div>
                    
                       
                </div>
	  <!--产品体系 end-->
  </Layout>
</template>
  <script  src="../components/testfiles/jqueryV173.js"   ></script>
 <script  src="../components/testfiles/myfocus-2.0.1.min.js"   ></script>
	 <script  src="../components/testfiles/Public.js"   ></script>
	 <script  src="../components/testfiles/swfobject_modified.js"   ></script>
 <script  src="../components/testfiles/swfobject.js"   ></script>
 <script  src="../components/testfiles/CgAGTF1b0hWAUVYvAAAl0Pj6dE85579.js"   ></script>
  <script  src="../components/testfiles/CgAGfFyhnmaALE0UAAA54HwM9Jc1551.js"   ></script>


 
 
<script>
import {
  jjService
} from '@/helpers/jjService.js';
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import "../components/testfiles/CgAH6F5fC62ASfbNAABhl4YfMmQ599.css"
import "../components/testfiles/CgAGS10CCVOAF-CtAABYj-lSbcE936.css"
import vueSeamlessScroll from "vue-seamless-scroll";
 import { swiper, swiperSlide } from "vue-awesome-swiper";
  import "swiper/dist/css/swiper.css";
   import Vue from 'vue'
  import vueMiniPlayer from 'vue-mini-player'
  import 'vue-mini-player/lib/vue-mini-player.css'
  Vue.use(vueMiniPlayer)
 
    export default {
        name: 'Demo',
        components: {Icon, Layout, Separator, SectionTitle,vueSeamlessScroll, swiper,swiperSlide},
        data() {
            return {
				menulist:[],
				jjstr:'',
				 video: {
          // 视频播放源
          url: 'http://www.cqlfyd.com/xmw/files/gsjs.mp4',
          // 视频海报
          // cover: 'https://i.loli.net/2019/06/06/5cf8c5d9c57b510947.png',
          muted: false, // 是否静音播放
          loop: false, // 视频是否循环播放
          preload: 'auto', // 视频预加载
          poster: '', // 原生视频默认海报暂不设置
          volume: 1, // 默认音量
          autoplay: false, // 视频自动播放
				 },
				xwlist:[],
				//项目集合
				xmlist:[],
				//产品体系
				cplist:[],
				classOption2: {
	       step: 1.5, // 数值越大速度滚动越快
	        limitMoveNum: 2,  //开启无缝滚动的数据量 设置(数值<=页面展示数据条数不滚)
	        hoverStop: true, // 是否开启鼠标悬停stop
	        direction: 2, // 0向下 1向上 2向左 3向右
	        openWatch: true, // 开启数据实时监控刷新dom
	        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
	        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
	        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
	        
	      		},
 swiperOption: {
         autoplay: true,
         speed: 300,
         loop: true,
         pagination: {
          el: ".swiper-pagination", //分页器
          clickable: true,
         },
	  },
              
				imgArr:[],
				 videoOptions: {
                    controls:true,
                    src:
                        "http://www.jurenlou.com/files/1641803933834_915.mp4", // url地址
                },
                player: null,
                playTime:'',
                seekTime:'',
                current:'',
               
            }
        },
        	methods:{
				querAll(){
					 
					var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/xw/card/custom?service=getSyAll', null).then(function (response) {
         //新闻赋值
		  self.xwlist=response.data.rec.xwlist;
		   

			//项目赋值
			self.xmlist=response.data.rec.xMzxRet.xwmx;
		  self.xmlist.push();
		  //self.$refs.seamlessScroll.reset();
		  //首页配置
		   self.imgArr=response.data.rec.gsjeBeanR.fDESC;
		  self.jjstr = response.data.rec.gsjeBeanR.FNAME;
		  //产品体系
		  self.cplist=response.data.rec.cptxlist;
		  //菜单查询
		  self.menulist=response.data.rec.menuLists;
        })
				},
				
				querXw(){
					 
					var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/xw/card/custom?service=getSyXw', null).then(function (response) {
         
		  self.xwlist=response.data.xwlist;
		    console.log(document.getElementById("hello").innerText); // 你好啊
  self.$nextTick(() => {
			  
			  console.log(document.getElementById("hello").innerText); // 你好啊
        for (var i = 0; i < self.xwlist.length; i++) {
				if(i===0){
					document.getElementById(self.xwlist[i].id).className = "xn_c_index_138_typeName xn_c_index_138_typeName_cur";
					document.getElementById(self.xwlist[i].id+"contType").className="xn_c_index_138_contType xn_c_index_138_contType_cur";
				}else{
					document.getElementById(self.xwlist[i].id).className = "xn_c_index_138_typeName";
					document.getElementById(self.xwlist[i].id+"contType").className="xn_c_index_138_contType";
				}

			}
      });
        })
				},
				 querCd() {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getBbjl', null).then(function (response) {
         
          self.menulist=response.data.menulist;

        })
    },
				querXmzs(){
					debugger;
					var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/xmzs/card/custom?service=getXmlist&&pageNum=1&pageSize=10', null).then(function (response) {
         debugger;
		  self.xmlist=response.data.xMzxRet.xwmx;
		  self.xmlist.push();
		  self.$refs.seamlessScroll.reset();

        })
				},
				 querSypz(){
					debugger;
					var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/gsje/card/custom?service=getSypz', null).then(function (response) {
         
		  self.imgArr=response.data.gsjeBeanR.fDESC;
		  self.jjstr = response.data.gsjeBeanR.FNAME;
		//   self.xmlist.push();
		//   self.$refs.seamlessScroll.reset();

        })
				},
				querCptx(){
					debugger;
					var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/cptx/card/custom?service=getCptxlist', null).then(function (response) {
         
      self.cplist=response.data.cptxlist;
      
      
        })
				},
            // 播放回调
            onPlayerPlay(player) {
                // this.globalSetting = true
                console.log("player play!", player);
                // document.getElementsByClassName("vjs-control-bar").style.display = "block";
                // document.getElementsByClassName("vjs-control-bar").style.display = "block";
            },

            // 暂停回调
            onPlayerPause(player) {
                // this.globalSetting.controls = false;
                // console.log("player pause!", player);
                // var video = document.getElementById("video");
                // video.controls=false;
                // document.getElementsByClassName("vjs-control-bar").style.display = "none";
            },
             

        beforeDestroy() {
            if (this.player) {
                this.player.dispose()
            }
        },
			 
			handleOver (e) {
	 			  let self = this;
     		for (var i = 0; i < self.xwlist.length; i++) {
				if(self.xwlist[i].id===e){
					document.getElementById(self.xwlist[i].id).className = "xn_c_index_138_typeName xn_c_index_138_typeName_cur";
					document.getElementById(self.xwlist[i].id+"contType").className="xn_c_index_138_contType xn_c_index_138_contType_cur";
				}else{
					document.getElementById(self.xwlist[i].id).className = "xn_c_index_138_typeName";
					document.getElementById(self.xwlist[i].id+"contType").className="xn_c_index_138_contType";
				}

			}
		},

		},
		updated(){
		 
for (var i = 0; i < this.xwlist.length; i++) {
				if(i===0){
					document.getElementById(this.xwlist[i].id).className = "xn_c_index_138_typeName xn_c_index_138_typeName_cur";
					document.getElementById(this.xwlist[i].id+"contType").className="xn_c_index_138_contType xn_c_index_138_contType_cur";
				}else{
					document.getElementById(this.xwlist[i].id).className = "xn_c_index_138_typeName";
					document.getElementById(this.xwlist[i].id+"contType").className="xn_c_index_138_contType";
				}

			}

    },

 
		//进入页面后启动定时轮询
		mounted(){
			//var host = window.location.host;
			//alert(host);
			this.querAll();
			//  this.querXw();//查询新闻
			//   this.querXmzs();//产线项目
			//   this.querSypz();//查询首页配置
			//   this.querCptx();//查询产品体系
			//   this.querCd();//查询菜单
			// debugger;
			
		 
			
     		 
		}
    }
</script>
 <style scoped lang='less'>
 .xn_c_index_42_my {
    height: 39px;
    line-height: 30px;
    overflow: hidden;
    /* text-align: center; */
    padding-top: 15px;
    padding-left: 125px;
}		
.index-roll {
    overflow: hidden;
    height: 350x;
    width: 1100px;
	//margin: 0 0 0 0;
	margin:0 auto; 
    ul {
        display: flex;
        li {
            width: 360px;
            margin: 0 0px;
            padding: 0px 0;
            background-color: #f8f8f8;
            border-radius: 0px;
        }
        li:hover{
            cursor: pointer;
            border-bottom: 2px solid #43aaed;
        }
        img{
            width: 300px;
            height: 250px;
            display: block;
            margin: 0 0;
        }
    }
}
 </style>