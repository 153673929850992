<template>
  <Layout :footer-style="2">
    <div v-html="showZcdNr">
    
 </div>
	<div id="ea_c">
            <div class="n_main">
                <div class="n_content">
                    <div class="n_content_left">
                    <div class="n_content_left_wrap">

                        <div id="n_content_left_cnt" class="n_content_left_cnt">
                            <ul id="ShoveWebControl_VMenu2" class="left_typeUl left_typeUl_n000" style="display: block;">
</ul>
                          
                        </div>


                            <div id="n_content_right_name_r" class="n_content_right_name_r">
                               <ul>
                                    <li>
                                        <span class="n_r_wz1">
                                            
                                            <span name="_n_r_wz1">您现在的位置：</span>
                                            
                                        </span>
                                        <span class="n_r_wz2">
                                           <router-link   to="/">
               <span name="_n_r_wz2">首页</span>
                </router-link>
                                        </span>
                                        <span class="n_r_wz3">→</span>
                                        <span class="n_r_wz4">
                                             
                                            <span id="ShoveWebControl_Text_case"><router-link  :to="{name:'empty',params: {zcdid:zcdid,cruId:cruId,ejcd:ejcd,topath:'hylx'}}">
               <a href="#" class="curr1">行业领先</a>
                </router-link></span>
                                        </span>
                                         
                                    </li>
                                     
                                </ul>   
                                
                            </div>

    </div>
                    </div>
                    <div class="n_content_right">
                        <div class="n_content_right_name"></div> 
                        <div class="n_content_right_m">

    <div class="pro_view_con_box1">
        
            <div id="xn_c_prodv_29_wrap" class="xn_c_prodv_29_wrap">
                
                    <div class="xn_c_prodv_29_wbox">
                        <div class="xn_c_prodv_29_bigimgwrap">
                            <div class="xn_c_prodv_29_bigimg" id="xn_c_prodv_29_bigimg">
                                 
                                        <img   alt="" title="" :src="cptxBean.FTPDZ">
                                    
                            </div>
                        </div>
                        <div class="xn_c_prodv_29_subimg" id="xn_c_prodv_29_subimg">
                            <div class="xn_c_prodv_29_btn" id="xn_c_prodv_29_btnleft"></div>
                            <ul id="xn_c_prodv_29_srollbox" style="width: 172px;">
                                
                                        <li class="xn_c_prodv_29_curr">
                                            <img :src="cptxBean.FTPDZ" alt="" title="">
                                        </li>
                                    
                            </ul>
                            <div class="xn_c_prodv_29_btn" id="xn_c_prodv_29_btnright"></div>
                        </div>
                    </div>
                
                
            </div>
        
        
            <div id="xn_c_prodv_60_wrap" class="xn_c_prodv_60_wrap">
                
                <div class="xn_c_prodv_60_name">
                    <h1>
                    <span id="xn_c_prodv_60_nameText">{{cptxBean.FNAME}}</span>
                    </h1>
                </div>
                <div class="xn_c_prodv_4_neirong"> 
                    <span id="ShoveWebControl_Texttestcon11"></span>
                </div>
                
                
            </div>
        
    </div>


    

                        </div>                        
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <div id="pview_complex_sidebar" class="pview_complex_sidebar">
                <div class="clear"></div>
            </div>
            <div id="pview_complex_content" class="pview_complex_content">
            </div>            
        </div>    
                    
 
 
	   
  </Layout>
</template>
   


 
 
<script>
    import Layout from '../components/common/Layout'
 import {
  jjService
} from '@/helpers/jjService.js';
    export default {
        name: 'cpxq',
        components: {  Layout},
        data() {
            return {
               cptxBean:{},
                showZcdNr:"",
                  cruId:this.$route.params.cruId,
                //  ejcd:this.$route.params.ejcd,
                //  cruName:"",
                //  zcdid:this.$route.params.zcdid,

    
            }
        },
    // 页面销毁之前
    beforeDestroy() {
        sessionStorage.removeItem('paramsData')
   	},
        created(){
            debugger;
            var params = [];
          if(this.$route.params.cruId){//从菜单点进来-有参数，直接取菜单参数,并将参数放入缓存
               
                 params = this.$route.params;
	            sessionStorage.setItem("paramsData", JSON.stringify(params));
            }else{//从浏览器返回或者刷新-无参数,取session中的参数,并且每次session中的参数都要刷新
                  params = JSON.parse(sessionStorage.getItem("paramsData"));
            }
             
                 this.cruId = params.cruId;
             
             this.querAllZcd('cptx','DB_DEMO_MENU_DETAIL');
             this.querAll(this.cruId);//查询产品体系明细
             
        },
        	methods:{
				querAllZcd(fid,tableName) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/menu/card/custom?service=getNr&&fid='+fid+'&&tableName='+tableName, null).then(function (response) {
         
          self.showZcdNr=response.data.showNr;

        })
    },
            querAll(fid) {
     
         var self = this;
        let headers = {
          'Content-Type': 'multipart/form-data'
        }     
       
          jjService.queryTest(jjService.apisrc+'/plugin/demo/cptx/card/custom?service=getCptx&&fid='+fid, null).then(function (response) {
         
          self.cptxBean=response.data.cptxBean;

        })
    },
    getListData(tab){
       
        this.querAll(tab.name,"DB_DEMO_MENU_DETAIL");
    },
		},
		 
    }
</script>
 <style scoped src="./高层及多层建筑 - 建筑 - 重庆渝隆远大住宅工业有限公司_files/CgAGS10CCVSALBlzAABWr3N1iMY544.css">
 

  </style>