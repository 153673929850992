var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        "row row--30 align-items-center",
        {'flex-row-reverse': _vm.tabStyle === 2},
        {'flex-column-reverse': _vm.tabStyle === 3} ]},[_c('div',{class:[
            {'col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30': _vm.tabStyle === 1 || _vm.tabStyle === 2},
            {'col-lg-10 offset-lg-1 mt--40': _vm.tabStyle === 3}
        ]},[_c('div',{class:[
                "advance-tab-button advance-tab-button-1",
                {'right-align': _vm.tabStyle === 2},
                {'right-top': _vm.tabStyle === 3} ]},[_c('ul',{class:["nav nav-tabs tab-button-list", {'row': _vm.tabStyle === 3}],attrs:{"role":"tablist"}},_vm._l((_vm.tabData.tabContent),function(tabMenu,index){return _c('li',{key:index,class:["nav-item", {'col-lg-4': _vm.tabStyle === 3}],attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link tab-button",class:{'active': index === 0},attrs:{"id":((tabMenu.menu) + "-tab"),"data-bs-toggle":"tab","data-bs-target":("#" + (tabMenu.menu)),"type":"button","role":"tab","aria-controls":tabMenu.menu,"aria-selected":index === _vm.activeTab ? 'true' : 'false'},on:{"click":function($event){_vm.activeTab = index}}},[_c('div',{staticClass:"tab"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(tabMenu.title))]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(tabMenu.description)+" ")])])])])}),0)])]),_c('div',{class:[
            {'col-lg-8 col-md-12 col-sm-12 col-12': _vm.tabStyle === 1 || _vm.tabStyle === 2},
            {'col-lg-10 offset-lg-1': _vm.tabStyle === 3}
        ]},[_c('div',{staticClass:"tab-content"},_vm._l((_vm.tabData.tabContent),function(content,index){return _c('div',{key:index,staticClass:"tab-pane fade advance-tab-content-1",class:{'show active': index === 0},attrs:{"id":content.menu,"role":"tabpanel","aria-labelledby":((content.menu) + "-tab")}},[_c('div',{class:["thumbnail", {'text-center': _vm.tabStyle === 3}]},[_c('img',{attrs:{"src":content.image,"alt":"Advance tab image"}})])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }