import { render, staticRenderFns } from "./xwxq.vue?vue&type=template&id=582766a1&scoped=true&"
import script from "./xwxq.vue?vue&type=script&lang=js&"
export * from "./xwxq.vue?vue&type=script&lang=js&"
import style0 from "./企业新闻 - 建筑 - 住宅 - 重庆渝隆远大住宅工业有限公司_files/CgAGS10CCVKAEj9oAAA_RVsR5CE119.css?vue&type=style&index=0&id=582766a1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "582766a1",
  null
  
)

export default component.exports